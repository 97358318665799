<template>
    <div class="modal modal-cover fade show" style="display: block;" aria-modal="true" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header p-0 py-3">
                    <h3 class="modal-title">{{ this.titlePage }}</h3>
                    <button type="button" class="btn-close" @click="turnOffAdd"></button>
                </div>
                <div class="modal-body p-0">
                    <div class="card">
                        <div class="card-body">
                            <template v-if="error !== null">
                                <Alert 
                                    :type="error.type" 
                                    :message="error.message"/>
                            </template>
                            <div class="alert alert-success" v-if="messageSuccess!==null">
                                {{ messageSuccess }}
                            </div>
                            <div class="mb-3">
                                <label class="form-label">Tên</label>
                                <input 
                                    id="name" 
                                    name="name" 
                                    type="text" 
                                    v-model="name" 
                                    class="form-control"
                                    :class="validator.name !== null && validator.name.length > 0 ? 'is-invalid': ''"/>
                                <div class="invalid-feedback" v-if="validator.name !== null && validator.name.length > 0">{{ validator.name }}</div>
                            </div>
                            <div class="mb-3">
                                <label class="form-label">Mô tả</label>
                                <textarea 
                                    class="form-control" 
                                    id="describe" 
                                    name="describe" 
                                    v-model="describe" 
                                    rows="3"
                                    :class="validator.describe !== null && validator.describe.length > 0 ? 'is-invalid': ''"
                                ></textarea>
                                <div class="invalid-feedback" v-if="validator.describe !== null && validator.describe.length > 0">{{ validator.describe }}</div>
                            </div>
                            <div class="mb-3">
                                <label class="form-label">Icon</label>
                                <input 
                                    id="icon" 
                                    name="icon" 
                                    type="text" 
                                    v-model="icon" 
                                    class="form-control"
                                    :class="validator.icon !== null && validator.icon.length > 0 ? 'is-invalid': ''"/>
                                <div class="invalid-feedback" v-if="validator.icon !== null && validator.icon.length > 0">{{ validator.icon }}</div>
                            </div>
                            <div class="mb-3">
                                <label class="form-label">Nhãn hiển thị</label>
                                <input 
                                    id="lable" 
                                    name="lable" 
                                    type="text" 
                                    v-model="lable" 
                                    class="form-control"
                                    :class="validator.lable !== null && validator.lable.length > 0 ? 'is-invalid': ''"/>
                                <div class="invalid-feedback" v-if="validator.lable !== null && validator.lable.length > 0">{{ validator.lable }}</div>
                            </div>
                            <div class="mb-3">
                                <label class="form-label">Tên route</label>
                                <input 
                                    id="route" 
                                    name="route" 
                                    type="text" 
                                    v-model="route" 
                                    class="form-control"
                                    :class="validator.route !== null && validator.route.length > 0 ? 'is-invalid': ''"/>
                                <div class="invalid-feedback" v-if="validator.route !== null && validator.route.length > 0">{{ validator.route }}</div>
                            </div>
                            <div class="mb-3">
                                <label class="form-label">Nhãn</label>
                                <select class="form-select"
                                    name="header"
                                    id="header"
                                    v-model="header" 
                                >
                                    <option value="">Chọn một nhãn</option>
                                    <option v-for="(headerItem) in headers"
                                        v-bind:key="headerItem" 
                                        :value="headerItem.id">
                                            {{ headerItem.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="card-footer bg-none" style="z-index: 9;">
                            <button type="submit" class="btn btn-outline-theme" @click="onSubmit">Lưu dữ liệu</button>
                            <button type="button" class="btn btn-outline-danger ms-1" @click="turnOffAdd">Huỷ</button>
                        </div>
                        <div class="card-arrow">
                            <div class="card-arrow-top-left"></div>
                            <div class="card-arrow-top-right"></div>
                            <div class="card-arrow-bottom-left"></div>
                            <div class="card-arrow-bottom-right"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-backdrop fade show"></div>
</template>

<script>
    import Helper from '../../../helper/utils';
    import request from '../../../helper/request';
    import Alert from '../../components/Alert.vue';

    export default {
        name: 'AddMenu',
        emits: ["callback"],
        data() {
            return {
                error: null,
                headers: [],
                header: null,
                titlePage: 'Thêm menu',
                messageSuccess: null,
                name: null,
                describe: null,
                icon: null,
                lable: null,
                route: null,
                validator: {
                    name: null,
                    describe: null,
                    icon: null,
                    lable: null,
                    route: null
                }
            };
        },
        props: {
            header_id: {
                type: String,
                default: null,
            },
            id: {
                type: String,
                default: null,
            }
        },
        components: {
            Alert
        },
        methods: {
            getParams: function() {
                const params = {
                    name: this.name,
                    describe: this.describe,
                    icon: this.icon,
                    lable: this.lable,
                    route: this.route
                };

                if(!Helper.isEmpty(this.header)) {
                    params.header_id = this.header;
                }

                return params;
            },
            resultResponse: function (data) {
                const keys = {
                    name: 'Tên',
                    describe: 'Mô tả',
                    icon: 'Icon',
                    lable: 'Nhãn hiển thị',
                    route: 'Đường dẫn'
                };
                
                if(data.status === 'success') {
                    this.countDown = 3;
                    this.messageSuccess = `Cập nhật dữ liệu thành công. màn hình sẽ tự tắt sau ${this.countDown}s`;
                    this.countDownTimer();
                } else {
                    const err = Helper.responseError(data, keys);
                    if(err.reason === 'Validator') {
                        const messages = JSON.parse(err.message);
                        messages.forEach(element => {
                            if(element.key === 'header_id') {
                                this.countDown = 3;
                                this.error = {
                                    type: 'error',
                                    message_target: element.message,
                                    message: `${element.message}, màn hình sẽ tự tắt sau ${this.countDown}s`
                                };
                                this.countDownTimer();
                            } else this.validator[element.key] = element.message;
                        });
                    } else {
                        this.error = err;
                    }
                }
            },
            insert: function() {
                request.post(`${process.env.VUE_APP_BASE_API}/v1/api/administrator/menu`, this.getParams())
                    .then((data) => {
                        this.resultResponse(data);
                    })
                    .catch((err) => {
                        console.log(err);
                        this.$router.push({ name: 'ErrorServerError'});
                    });
            },
            update: function() {
                request.put(`${process.env.VUE_APP_BASE_API}/v1/api/administrator/menu`, {
                        ...this.getParams(),
                        ...{id: this.id}
                    })
                    .then((data) => {
                        this.resultResponse(data);
                    })
                    .catch((err) => {
                        console.log(err);
                        this.$router.push({ name: 'ErrorServerError'});
                    });
            },
            onSubmit: function () {
                Object.keys(this.validator).forEach((v) => {
                    this.validator[v] = null;
                });
                
                if(!Helper.isEmpty(this?.id ?? null)) this.update();
                else this.insert();
            },
            countDownTimer: function() {
                if (this.countDown > 0) {
                    setTimeout(() => {
                        this.countDown -= 1
                        if(this.error === null) this.messageSuccess = `Cập nhật dữ liệu thành công. màn hình sẽ tự tắt sau ${this.countDown}s`;
                        else this.error.message = `${this.error.message_target}, màn hình sẽ tự tắt sau ${this.countDown}s`;

                        this.countDownTimer()
                    }, 1000)
                } else this.turnOffAdd(true);
            },
            turnOffAdd: function(loaddata = null) {
                if(this.error === null) this.$emit('callback', false, null, loaddata);
                else this.$emit('callback', false, null, false);
            }
        },
        mounted: function () {
            if(this.header_id !== null) {
                this.header = this.header_id;
            }
            request.get(`${process.env.VUE_APP_BASE_API}/v1/api/administrator/menu/header`)
                .then((resp) => {
                    if(resp.status === 'success') {
                        this.headers = resp?.response?.data ?? [];
                    } else {
                        this.error = Helper.responseError(resp, {});
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.$router.push({ name: 'ErrorServerError'});
                });
            if(!Helper.isEmpty(this?.id ?? null)) {
                request.get(`${process.env.VUE_APP_BASE_API}/v1/api/administrator/menu/${this.id}`)
                    .then((resp) => {
                        if(resp.status_code === 200) {
                            this.name = resp?.response?.detail?.name ?? null;
                            this.describe = resp?.response?.detail?.describe ?? null;
                            this.icon = resp?.response?.detail?.icon ?? null;
                            this.lable = resp?.response?.detail?.lable ?? null;
                            this.route = resp?.response?.detail?.route ?? null;
                            this.header = resp?.response?.detail?.header_id ?? null;
                            this.titlePage = `Chỉnh sửa : ${this.name}`;
                        } else this.error = Helper.responseError(resp, {});
                    }).catch((err) => {
                        console.log(err);
                        this.$router.push({ name: 'ErrorServerError'});
                    });
            }
        }
    }
</script>