import axios from 'axios';
import store from '../stores'
import FormData from 'form-data';

const getToken = () => {
    let user = store.getters['user/getUser'];
    if(typeof user === 'string') {
        user = JSON.parse(user);
    }
    if (user && user.access_token) {
        return user.access_token;
    } else {
        return null;
    }
}
const headers = {
    'Accept': 'application/json, text/plain, */*',
    'Accept-Language': 'en-US,en;q=0.9,vi;q=0.8,ja;q=0.7',
    'Access-Control-Allow-Origin': process.env.VUE_APP_BASE,
    'Content-Type': 'application/json',
}

const post = async (url, data) => {
    let option = {
        headers: headers
    };

    const token = getToken();
    if(token) option.headers.authorization = `Bearer ${token}`;

    const result = await axios.post(url, data, option).then(response => { 
        return { status:'success', status_code: response.status, response: response.data };
    }).catch(error => {
        if (error.response) { return {status:'error', status_code: error.response.status, response: error.response.data } }
        else return null;
    });
    
    return result;
}

const put = async (url, data) => {
    let option = {
        headers: headers
    };

    const token = getToken();
    if(token) option.headers.authorization = `Bearer ${token}`;

    const result = await axios.put(url, data, option).then(response => { 
        return { status:'success', status_code: response.status, response: response.data };
    }).catch(error => {
        if (error.response) { return {status:'error', status_code: error.response.status, response: error.response.data } }
        else return null;
    });
    
    return result;
}

const get = async (url) => {
    let option = {
        headers: headers
    };

    const token = getToken();
    if(token) {
        option.headers.authorization = `Bearer ${token}`;
    }

    const result = await axios.get(url, option).then(response => { 
        return { status:'success', status_code: response.status, response: response.data };
    }).catch(error => {
        if (error.response) { return {status:'error', status_code: error.response.status, response: error.response.data } }
        else return null;
    });
    
    return result;
}

const postFile = async (url, base64) => {
    let option = {
        headers: headers
    };

    const token = getToken();
    if(token) option.headers.authorization = `Bearer ${token}`;

    var data = new FormData();
    data.append('file', base64);

    const result = await axios.post(url, data, option).then(response => { 
        return { status:'success', status_code: response.status, response: response.data };
    }).catch(error => {
        if (error.response) { return {status:'error', status_code: error.response.status, response: error.response.data } }
        else return null;
    });

    return result;
}

const deleteData = async (url) => {
    let option = {
        headers: headers
    };

    const token = getToken();
    if(token) option.headers.authorization = `Bearer ${token}`;

    const result = await axios.delete(url, option).then(response => { 
        return { status:'success', status_code: response.status, response: response.data };
    }).catch(error => {
        if (error.response) { return {status:'error', status_code: error.response.status, response: error.response.data } }
        else return null;
    });
    
    return result;
}

export default {
    get: get,
    put: put,
    post: post,
    delete: deleteData,
    postFile: postFile,
    getToken: getToken
};