<template>
	<router-view/>
</template>

<script>
	import { defineRule } from 'vee-validate';
	import * as rules from '@vee-validate/rules';
	import app from './assets/plugins/app';
	import utils from './helper/utils';
	import router from './router';
	import store from '../src/stores';
	import request from '../src/helper/request';
	
	Object.keys(rules).forEach(rule => {
		if((typeof rules[rule]) === 'function')
			defineRule(rule, rules[rule]);
	});
	
	export default {
		name: 'app',
		components: {},
		mounted() {
			app.init();
		},
		methods: {
			toggleAction(eleId, addClass, removeClass) {
				if(!utils.isEmpty(removeClass))
					document.getElementById(eleId).classList.remove(removeClass);
				if(!utils.isEmpty(addClass))
					document.getElementById(eleId).classList.add(addClass);
			}
		},
		setup() {
			const refetchToken = () => {
				const user = store.getters['user/getUser'];
				request.put(`${process.env.VUE_APP_BASE_API}/v1/api/administrator/user/refresh-token`, {
						refresh_token: user.refresh_token
					})
					.then((res) => {
						if(res?.status === 'error') {
							store.dispatch('user/logout');
							router.push({ name: 'AuthLogin'});
						} else store.dispatch('user/setInfor', res.response.user);
					})
					.catch((err) => {
						console.log(err);
						store.dispatch('user/logout');
						router.push({ name: 'AuthLogin'});
					})
			}
			const isTokenExpired = () => {
				const isAuth = store.getters['user/getAuth'];
				if(isAuth) {
					request.get(`${process.env.VUE_APP_BASE_API}/v1/api/administrator/user/me`)
						.then((me) => {
							if(me?.status === 'success') {
								store.dispatch('user/setInfor', me.response.user);
							} else refetchToken();
						})
						.catch((err) => {
							console.log(err);
							refetchToken();
						})
				}
			}
			
			setInterval(isTokenExpired, Number(process.env.VUE_APP_TOKEN_EXPIRES_IN) * 60 * 1000);
		}
	}
</script>

<style>
.router-link-active {
	color: #fff !important;
}
</style>