<template>
    <Header/>
    <Menu />
    <div id="content" class="app-content">
        <div class="d-flex align-items-center mb-md-3 mb-2">
            <div class="flex-fill">
                <Breadcrumb :items="breadcrumbs" />
                <h1 class="page-header mb-0">
                    Phân quyền
                </h1>
            </div>
            <div class="ms-auto">
                <button @click="turnOnOffAdd(true, false)" class="btn btn-outline-theme">
                    <i class="fa fa-plus-circle me-1"></i> 
                    Thêm Mới
                </button>
            </div>
        </div>

        <div class="row" v-if="this.error">
            <div class="col-lg-12">
                <alert :type="this.error.type" :message="this.error.message"/>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex fw-bold small mb-3">
                            <span class="flex-grow-1">Danh sách các phân quyền hiện có</span>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-striped align-middle">
                                <thead>
                                    <tr>
                                        <th scope="col">Phân Quyền</th>
                                        <th scope="col" class="text-center">Số API</th>
                                        <th scope="col" class="text-center">Số Menu</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-if="data === null || data.length === 0">
                                        <tr>
                                            <td class="text-center" colspan="4">
                                                Chưa có gì để hiển thị
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-else>
                                        <tr v-for="(item) in data"  v-bind:key="item">
                                            <td>
                                                <div class="flex-fill">
                                                    <div class="fs-13px text-truncate">{{item.name}}</div>
                                                    <div class="fs-12px text-white text-opacity-50">{{item.describe}}</div>
                                                </div>
                                            </td>
                                            <td class="text-center">
                                                {{ item.roles.length }}
                                            </td>
                                            <td class="text-center">
                                                {{ item?.count_menu ?? 0 }}
                                            </td>
                                            <td class="text-end">
                                                <router-link :to="{ name: 'MenuIndex', query: {role_id:item.id, type: 'edit'} }" tag="button" class="btn btn-rounded btn-sm bg-theme bg-opacity-20 text-theme fw-600 rounded me-1">
                                                    <i class="fa fa-fw fa-list-alt"></i>
                                                </router-link>
                                                <router-link :to="{ name: 'Moduleindex', query: {role_id:item.id, type: 'edit'} }" tag="button" class="btn btn-rounded btn-sm bg-theme bg-opacity-20 text-theme fw-600 rounded me-1">
                                                    <i class="fa fa-fw fa-list"></i>
                                                </router-link>
                                                <a href="javascript:void(0)" @click="PopupDelete(item.id)" class="btn btn-rounded btn-sm bg-theme bg-opacity-20 text-theme fw-600 rounded">
                                                    <i class="fa fa-fw fa-trash"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>

                        <template v-if="this.paginate !== null">
                            <Paginate 
                                :current="this.paginate.page"
                                :total="this.paginate.pageCount"
                                :next_page="this.paginate.hasNextPage"
                                :previous_page="this.paginate.hasPreviousPage"
                                :per_page="this.paginate.take"
                                :data_total="this.paginate.itemCount"
                                @callback="loadData"/>
                        </template>
                    </div>
                    <div class="card-arrow">
                        <div class="card-arrow-top-left"></div>
                        <div class="card-arrow-top-right"></div>
                        <div class="card-arrow-bottom-left"></div>
                        <div class="card-arrow-bottom-right"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <AddComponents 
        @turnOffAdd="turnOnOffAdd"
        v-if="this?.popup?.add !== false"/>
    <deleteComponent 
        v-if="this?.popup?.deleteId !== null"
        @callback="PopupDelete"
        :id="this.popup.deleteId"
        :url="getLinkDelete()"/>
    <ScrollToTop />
</template>

<script>
    import Menu from '../components/Menu.vue';
    import alert from '../components/Alert.vue';
    import Header from '../components/Header.vue';
    import ScrollToTop from '../components/ScrollToTop.vue';
    import Breadcrumb from '../components/Breadcrumb.vue';
    import Paginate from '../components/Paginate.vue';
    
    import utils from '../../helper/utils';
    import request from '../../helper/request';

    import AddComponents from './Components/add.vue'
    import deleteComponent from '../components/Popup/Delete.vue'

    export default {
        name: 'ModuleList',
        components: {
            Header,
            Menu,
            ScrollToTop,
            alert,
            AddComponents,
            Breadcrumb,
            Paginate,
            deleteComponent
        },
        data() {
            return {
                error: null,
                data: [],
                paginate: [],
                breadcrumbs: [{'link': 'Roleindex', 'text': 'Phân quyền'}],
                popup: {
                    add: false,
                    deleteId: null
                }
            };
        },
        methods: {
            turnOnOffAdd: function(status, loadData, idResult = null) {
                this.popup.add = status;
                if(loadData === true && idResult !== null) {
                    this.$router.push({ name: 'Moduleindex',query :{role_id: idResult, type: 'add'}});
                }
            },
            loadData: function() {
                request.get(`${process.env.VUE_APP_BASE_API}/v1/api/administrator/roles`)
                    .then((resp) => {
                        if(resp.status_code === 200) {
                            this.data = resp?.response?.data ?? [];
                            this.paginate = resp?.response?.meta??null;
                        } else this.error = utils.responseError(resp, {});
                    }).catch((err) => {
                        console.log(err);
                        this.$router.push({ name: 'ErrorServerError'});
                    });
            },
            delete: function (id) {
                request.delete(`${process.env.VUE_APP_BASE_API}/v1/api/administrator/roles/${id}`)
                    .then((resp) => {
                        if(resp.status_code === 200) {
                            this.loadData();
                        } else this.error = utils.responseError(resp, {});
                    }).catch((err) => {
                        console.log(err);
                        this.$router.push({ name: 'ErrorServerError'});
                    });
            },
            getLinkDelete: function() {
                return `${process.env.VUE_APP_BASE_API}/v1/api/administrator/roles/${this.popup.deleteId}`;
            },
            PopupDelete: function(id, result = false) {
                this.popup.deleteId = id;
                if(result === true) {
                    this.loadData();
                }
            }
        },  
        mounted: function () {
            this.loadData();
        },
		created() {
            document.body.classList.remove('pace-top');
            document.getElementById('app').classList.remove('app-full-height','app-without-header');
        }
    }
</script>
