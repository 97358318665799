<template>
<!-- BEGIN #sidebar -->
<div id="sidebar" class="app-sidebar">
    <!-- BEGIN scrollbar -->
    <div class="app-sidebar-content" data-scrollbar="true" data-height="100%">
        <!-- BEGIN menu -->
        <div class="menu">
            <template v-for="(item) in permissions"  v-bind:key="item">
                <div class="menu-header">{{item.lable}}</div>
                <div class="menu-item" v-for="(itemMenu) in item.menu"  v-bind:key="itemMenu">
                    <router-link :to="{ name: itemMenu.route }" class="menu-link">
                        <span class="menu-icon"><i class="bi" :class="itemMenu.icon"></i></span>
                        <span class="menu-text">{{itemMenu.lable}}</span>
                    </router-link>
                </div>
            </template>
            
        </div>
    </div>
</div>
</template>

<script>
    export default {
        name: 'Menu',
        data() {
            return {
                permissions: []
            };
        },
        mounted: function () {
            this.permissions = this.$store.getters['user/getPermission'];
        }
    }
</script>

<style lang="css" scoped>
</style>