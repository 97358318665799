<template>
    <div class="d-md-flex align-items-center">
        <div class="me-md-auto text-md-left text-center mb-2 mb-md-0">
            Showing {{ this.getCurrentPage() }} to {{ this.current * this.per_page }} of {{data_total}} entries
        </div>
        <ul class="pagination mb-0 justify-content-center">
            <li class="page-item disabled" v-if="previous_page===false">
                <span class="page-link">«</span>
            </li>
            <li class="page-item " v-else>
                <a class="page-link" href="javascript:void(0)" @click="callback(this.current - 1)">«</a>
            </li>
            <template v-for="(item) in getPaginates"  v-bind:key="item">
                
                <li class="page-item disabled" v-if="item === -1">
                    <span class="page-link">
                        <i class="fas fa-ellipsis-h"></i>
                    </span>
                </li>
                <li class="page-item" :class="this.current === item ? 'active': ''" v-else>
                    <a class="page-link" href="javascript:void(0)" @click="callback(item)">{{item}}</a>
                </li>
            </template>
            <li class="page-item disabled" v-if="next_page===false">
                <span class="page-link">»</span>
            </li>
            <li class="page-item" v-else>
                <a class="page-link" href="javascript:void(0)" @click="callback(this.current + 1)">»</a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    emits: ["callback"],
    props: {
        current: {
            type: Number,
            default: 0,
        },
        per_page: {
            type: Number,
            default: 10,
        },
        total:{
            type: Number,
            default: 1,
        },
        next_page: {
            type: Boolean,
            default: false,
        },
        previous_page: {
            type: Boolean,
            default: false,
        },
        data_total: {
            type: Number,
            default: 0,
        }
    },
    methods: {
        callback(page) {
            this.$emit('callback', page);
        },
        getCurrentPage() {
            const showStart = (this.current - 1) * this.per_page;
            return showStart + 1;
        }
    },
    computed: {
        getPaginates() {
            let start = (this.current - 2) < 1 ? 1 : this.current - 2;
            if((start + 5) >= this.total) {
                start = this.total - 4;
            }
            if(start<=1) start = 1; 
            let output = [];
            if(start > 1) {
                output.push(-1);
            }

            for(let i = start; i < (start + 5) && i <= this.total; i++) {
                output.push(i);
            }
            if((start + 5) < this.total) {
                output.push(-1);
            }

            return output;
        }
    }
};
</script>

