<template>
    <div class="card">
        <div class="card-body">
            <div class="table-responsive">
                <table class="table table-striped align-middle">
                    <thead>
                        <tr>
                            <th scope="col">Ngày</th>
                            <th scope="col">Diễn giải</th>
                            <th scope="col">Ghi Chú</th>
                            <th scope="col">Cập nhật</th>
                            <th scope="col">Thay đổi</th>
                            <th scope="col">Tổng</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="Transactions === null || Transactions.length === 0">
                            <tr>
                                <td class="text-center" colspan="7">
                                    Chưa có gì để hiển thị
                                </td>
                            </tr>
                        </template>
                        <template v-else>
                        <tr v-for="(item) in Transactions"  v-bind:key="item">
                            <!-- <td>{{ showStt(index) }}</td> -->
                            <td>
                                <div class="flex-1 ps-3">
                                    <div class="mb-1">
                                        <small  v-if="item.type.code === 'DIVIDEND'" class="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-success bg-opacity-25 text-success text-opacity-75 pt-5px">
                                            {{item.type.text}}
                                        </small>
                                        <small  v-if="item.type.code === 'RECHARGE'" class="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-secondary bg-opacity-25 text-secondary text-opacity-75 pt-5px">
                                            {{item.type.text}}
                                        </small>
                                        <small  v-if="item.type.code === 'BUY'" class="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-primary bg-opacity-25 text-primary text-opacity-75 pt-5px">
                                            {{item.type.text}}
                                        </small>
                                        <small  v-if="item.type.code === 'SELL'" class="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-info bg-opacity-25 text-info text-opacity-75 pt-5px">
                                            {{item.type.text}}
                                        </small>
                                        <small  v-if="item.type.code === 'WITHDRAWALS'" class="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-warning bg-opacity-25 text-warning text-opacity-75 pt-5px">
                                            {{item.type.text}}
                                        </small>
                                        <small  v-if="item.type.code === 'PAY_THE_DEPOSIT_FEE'" class="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-success bg-opacity-25 text-success text-opacity-75 pt-5px">
                                            {{item.type.text}}
                                        </small>
                                        <small  v-if="item.type.code === 'INTEREST_RATE'" class="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-light bg-opacity-25 text-light text-opacity-75 pt-5px">
                                            {{item.type.text}}
                                        </small>
                                    </div>
                                    <div class="fw-500 text-white">{{item.day}}</div>
                                    {{item.company}}
                                </div>
                            </td>
                            <!-- <td>
                                <span v-if="item.type.code === 'DIVIDEND'" class="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                    <i class="fa fa-circle fs-9px fa-fw me-5px"></i> 
                                    {{item.type.text}}
                                </span>
                                <span v-else-if="item.type.code === 'RECHARGE'" class="badge border border-secondary text-secondary px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                    <i class="fa fa-circle fs-9px fa-fw me-5px"></i> 
                                    {{item.type.text}}
                                </span>
                                <span v-else-if="item.type.code === 'BUY'" class="badge border border-primary text-primary px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                    <i class="fa fa-circle fs-9px fa-fw me-5px"></i> 
                                    {{item.type.text}}
                                </span>
                                <span v-else-if="item.type.code === 'SELL'" class="badge border border-info text-info px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                    <i class="fa fa-circle fs-9px fa-fw me-5px"></i> 
                                    {{item.type.text}}
                                </span>
                                <span v-else-if="item.type.code === 'WITHDRAWALS'" class="badge border border-warning text-warning px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                    <i class="fa fa-circle fs-9px fa-fw me-5px"></i> 
                                    {{item.type.text}}
                                </span>
                                <span v-else-if="item.type.code === 'PAY_THE_DEPOSIT_FEE'" class="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                    <i class="fa fa-circle fs-9px fa-fw me-5px"></i> 
                                    {{item.type.text}}
                                </span>
                                <span v-else-if="item.type.code === 'INTEREST_RATE'" class="badge border border-light text-light px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                    <i class="fa fa-circle fs-9px fa-fw me-5px"></i> 
                                    {{item.type.text}}
                                </span>
                            </td> -->
                            <td>
                                {{item.explain}}
                            </td>
                            <td>
                                {{item.content}}
                            </td>
                            <td>
                                <div class="flex-fill">
                                    <div class="fs-13px text-truncate w-100px">{{item.created_at.date}}</div>
                                    <div class="fs-12px text-white text-opacity-50">{{item.created_at.time}}</div>
                                </div>
                            </td>
                            <td>
                                <table class="mb-2">
                                    <tbody>
                                        <tr>
                                            <td class="pe-3">Số tiền:</td>
                                            <td class="text-opacity-75 fw-500">{{formatCurrency(item.price)}}</td>
                                        </tr>
                                        <tr>
                                            <td class="pe-3">Thuế:</td>
                                            <td class="text-white text-opacity-75 fw-500" v-if="item.tax !== null">{{formatCurrency(item.tax)}}</td>
                                            <td v-else>-</td>
                                        </tr>
                                        <tr>
                                            <td class="pe-3 text-nowrap">Phí giao dịch:</td>
                                            <td class="text-white text-opacity-75 fw-500" v-if="item.fee !== null">{{formatCurrency(item.fee)}}</td>
                                            <td v-else>-</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td class="fw-400" :class="item.total_price > 0 ? 'text-theme':'text-danger'">
                                {{formatCurrency(item.total_price)}}
                            </td>
                            <td><a href="#" class="text-decoration-none text-white"><i class="bi bi-search"></i></a></td>
                        </tr>
                        </template>
                    </tbody>
                </table>
            </div>
            <template v-if="this.PaginateMeta !== null">
                <Paginate 
                    :current="this.PaginateMeta.page"
                    :total="this.PaginateMeta.pageCount"
                    :next_page="this.PaginateMeta.hasNextPage"
                    :previous_page="this.PaginateMeta.hasPreviousPage"
                    :per_page="this.PaginateMeta.take"
                    :data_total="this.PaginateMeta.itemCount"
                    @callback="loadData"/>
            </template>
        </div>
        <div class="card-arrow">
            <div class="card-arrow-top-left"></div>
            <div class="card-arrow-top-right"></div>
            <div class="card-arrow-bottom-left"></div>
            <div class="card-arrow-bottom-right"></div>
        </div>
    </div>
</template>

<script>
import request from '../../../helper/request';
import util from '../../../helper/utils';

import Paginate from '../../components/Paginate.vue';
export default {
    name: 'TransactionListStock',
    data() {
        return {
            Transactions: null,
            PaginateMeta: null
        };
    },
    components: {
        Paginate
    },
    methods: {
        formatCurrency: function(value) {
            return util.formatCurrency(value);
        },
        async loadData(page = 1) {
            const response = await request.get(`${process.env.VUE_APP_BASE_API}/v1/api/administrator/finance/stock?page=${page}`)
                .then((data) => data);
            if(response.status === 'success') {
                this.Transactions = response?.response?.data??null;
                this.PaginateMeta = response?.response?.meta??null;
            } else {
                this.Transactions = null;
            }
        },

        showStt(value) {
            const showStart = ((this.PaginateMeta.page) * this.PaginateMeta.pageCount) - this.PaginateMeta.pageCount;
            return (showStart) + value + 1;
        }
    },
    mounted: async function () {
        this.loadData();
    },
}
</script>