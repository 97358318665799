<template>
    <input
        :name="name"
        :id="name"
        type="file"
        ref="file"
        @input="handleChange"
        @blur="handleBlur"
        :class="classObject"
        :readonly="readonly"
    />
    <template v-if="(errorMessage != null && errorMessage.length > 0)">
        <div class="invalid-feedback" style="display:block">
            {{ errorMessage }}
        </div>
    </template>
    <template v-else-if="meta.valid && successMessage !== null && successMessage.length > 0">
        <div>
            <div class="valid-feedback" style="display:block">
                {{ successMessage }}
            </div>
        </div>
    </template>
</template>

<script>
import { toRef } from "vue";
import { useField } from "vee-validate";

export default {
    data() {
        return {
            change: false
        };
    },
    props: {
        name: {
            type: String,
            required: true,
        },
        successMessage: {
            type: String,
            default: "",
        },
        className: {
            type:String,
            default: ""
        },
        readonly: {
            type:Boolean,
            default: false
        }
    },
    setup(props) {
        const name = toRef(props, "name");
        const {
            errorMessage,
            handleBlur,
            handleChange,
            meta,
        } = useField(name, undefined, {
            initialValue: props.value,
        });

        return {
            handleChange,
            handleBlur,
            errorMessage,
            meta,
        };
    },
    methods: {
        keyupChange() {
            this.change = true;
        }
    },
    computed: {
        classObject() {
            let result = `form-control ${this.className}`;
            if(this.change) {
                if(this.errorMessage) result += ' is-invalid ';
                else if(this.meta.valid) result += ' is-valid ';
            }
            return result;
        }
    }
};
</script>
