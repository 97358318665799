<template>
    <Header/>
    <Menu />
    <div id="content" class="app-content">
        <div class="d-flex align-items-center mb-md-3 mb-2">
            <div class="flex-fill">
                <Breadcrumb :items="breadcrumbs" />
                <h1 class="page-header mb-0">
                    Menu
                </h1>
            </div>
            <div class="ms-auto">
                <button @click="handleAddToRole()" class="btn btn-outline-theme" v-if="this.role_id !== null">
                    <i class="fa fa-plus-circle me-1" v-if="this.loading === false"></i> 
                    <div class="spinner-border spinner-border-sm" v-else style="vertical-align:-0.2em"></div>
                    Thêm vào phân quyền
                </button>
            </div>
        </div>
        <div class="mb-sm-4 mb-3 d-sm-flex">
            <div class="mt-sm-0 mt-2 me-2">
                <a href="javascript:void(0)" @click="turnOnOffAdd(true)" class="text-white text-opacity-75 text-decoration-none">
                    <i class="fa fa-plus fa-fw me-1 text-theme"></i> Thêm header
                </a>
            </div>
            <div class="mt-sm-0 mt-2">
                <a href="javascript:void(0)" @click="turnOnOffAddMenu(true, null)" class="text-white text-opacity-75 text-decoration-none">
                    <i class="fa fa-plus fa-fw me-1 text-theme"></i> Thêm menu
                </a>
            </div>
        </div>
        <div class="row" v-if="this.error">
            <div class="col-lg-12">
                <div class="alert alert-success" v-if="messageSuccess!==null">
                    {{ messageSuccess }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12" v-if="messageSuccess!==null">
                <div class="alert alert-success">
                    {{ messageSuccess }}
                </div>
            </div>
            
            <div :class="(this.role_id === null?'col-lg-12':'col-lg-8')">
                <div class="card">
                    <div class="card-body">
                        <div class="card-header bg-none fw-bold d-flex align-items-center">
                            Danh sách menu
                        </div>
                        <div class="table-responsive">
                            <table class="table table-striped align-middle">
                                <tbody>
                                    <template v-if="this.showData()">
                                        <template v-for="(header) in data" v-bind:key="header">
                                            <tr class="table-primary" :data-id="header.id">
                                                <template v-if="header.type === 'HEADER'">
                                                    <td :colspan="(this.role_id === null?4:2)">
                                                        <div class="flex-fill">
                                                            <div class="fs-13px text-truncate w-250px">{{ header.name }}</div>
                                                            <div class="fs-12px text-white text-opacity-50">{{ header.describe }}</div>
                                                        </div>
                                                    </td>
                                                </template>
                                                <template v-else>
                                                    <td v-if="this.role_id !== null" style="width: 30px;">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" 
                                                                :id="('choose_' + header.id)" 
                                                                :value="header.id" 
                                                                style="margin-bottom: 0px;" 
                                                                v-model="chooses">
                                                        </div>
                                                    </td>
                                                    <td v-else style="width: 30px;">
                                                        <i class="bi" :class="(header.icon.length === 0 ?'fa-indent':header.icon)"></i>
                                                    </td>
                                                    <td>
                                                        <div class="flex-fill">
                                                            <div class="fs-13px text-truncate w-250px">{{ header.name }}</div>
                                                            <div class="fs-12px text-white text-opacity-50">{{ header.describe }}</div>
                                                        </div>
                                                    </td>
                                                    <td v-if="this.role_id === null">
                                                        {{ header.lable }}
                                                    </td>
                                                    <td v-if="this.role_id === null">
                                                        {{ header.route }}
                                                    </td>
                                                </template>
                                                <td class="text-end">
                                                    <a href="javascript:void(0)" v-if="header.type === 'HEADER'" @click="turnOnOffAddMenu(true, header.id)" class="btn btn-rounded btn-sm bg-theme bg-opacity-20 text-theme fw-600 rounded me-1">
                                                        <i class="fa fa-fw fa-plus"></i>
                                                    </a>
                                                    <a href="javascript:void(0)" v-if="header.type === 'HEADER'" @click="editHeader(header.id)" class="btn btn-rounded btn-sm bg-theme bg-opacity-20 text-theme fw-600 rounded me-1">
                                                        <i class="fa fa-fw fa-edit"></i>
                                                    </a>
                                                    <a href="javascript:void(0)" v-else @click="editMenu(header.id)" class="btn btn-rounded btn-sm bg-theme bg-opacity-20 text-theme fw-600 rounded me-1">
                                                        <i class="fa fa-fw fa-edit"></i>
                                                    </a>
                                                    <a href="javascript:void(0)" v-if="header.type === 'HEADER'" @click="PopupDelete(header.id)" class="btn btn-rounded btn-sm bg-theme bg-opacity-20 text-theme fw-600 rounded">
                                                        <i class="fa fa-fw fa-trash"></i>
                                                    </a>
                                                    <a href="javascript:void(0)" v-else @click="PopupDeleteMenu(header.id)" class="btn btn-rounded btn-sm bg-theme bg-opacity-20 text-theme fw-600 rounded">
                                                        <i class="fa fa-fw fa-trash"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr v-for="(menu) in (header?.menu ?? [])" v-bind:key="menu">
                                                <td v-if="this.role_id !== null" style="width: 30px;">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" 
                                                            :id="('choose_' + menu.id)" 
                                                            :value="menu.id" 
                                                            style="margin-bottom: 0px;" 
                                                            v-model="chooses">
                                                    </div>
                                                </td>
                                                <td v-else style="width: 30px;">
                                                    <i class="bi" :class="(menu.icon.length === 0 ?'fa-indent':menu.icon)"></i>
                                                </td>
                                                <td>
                                                    <div class="flex-fill">
                                                        <div class="fs-13px text-truncate w-250px">{{ menu.name }}</div>
                                                        <div class="fs-12px text-white text-opacity-50">{{ menu.describe }}</div>
                                                    </div>
                                                </td>
                                                <td v-if="this.role_id === null">
                                                    {{ menu.lable }}
                                                </td>
                                                <td v-if="this.role_id === null">
                                                    {{ menu.route }}
                                                </td>
                                                <td class="text-end">
                                                    <a href="javascript:void(0)" @click="editMenu(menu.id)" class="btn btn-rounded btn-sm bg-theme bg-opacity-20 text-theme fw-600 rounded me-1">
                                                        <i class="fa fa-fw fa-edit"></i>
                                                    </a>
                                                    <a href="javascript:void(0)" @click="PopupDeleteMenu(menu.id)" class="btn btn-rounded btn-sm bg-theme bg-opacity-20 text-theme fw-600 rounded">
                                                        <i class="fa fa-fw fa-trash"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <tr>
                                            <td class="text-center">Không có gì để hiển thị</td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="card-arrow">
                        <div class="card-arrow-top-left"></div>
                        <div class="card-arrow-top-right"></div>
                        <div class="card-arrow-bottom-left"></div>
                        <div class="card-arrow-bottom-right"></div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4" v-if="this.role_id !== null">
                <div class="card">
                    <div class="card-body">
                        <div class="card-header bg-none fw-bold d-flex align-items-center">
                            Review Menu
                            <a href="javascript:void(0)" @click="renderMenuForReview()" class="ms-auto text-muted text-decoration-none" title="Show menu"><i class="fa fa-eye"></i></a>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-striped align-middle">
                                <tbody>
                                    <template v-if="treeMenu.length === 0">
                                        <tr>
                                            <td class="text-center">Chưa chọn menu</td>
                                        </tr>
                                    </template>
                                    <template v-else>
                                        <template v-for="(itemChoose) in this.treeMenu" v-bind:key="itemChoose">
                                            <tr>
                                                <td v-if="itemChoose.type !== 'HEADER'">
                                                    <i class="fa" :class="(itemChoose.icon.length === 0 ?'fa-indent':itemChoose.icon)"></i>
                                                </td>
                                                <td v-else colspan="2">
                                                    <div class="flex-fill">
                                                        <div class="fs-13px text-truncate w-250px">{{ itemChoose.name }}</div>
                                                        <div class="fs-12px text-white text-opacity-50">{{ itemChoose.describe }}</div>
                                                    </div>
                                                </td>
                                                <td v-if="itemChoose.type !== 'HEADER'">
                                                    <div class="flex-fill">
                                                        <div class="fs-13px text-truncate w-250px">{{ itemChoose.name }}</div>
                                                        <div class="fs-12px text-white text-opacity-50">{{ itemChoose.describe }}</div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <template v-if="itemChoose.type === 'HEADER'">
                                                <tr v-for="(itemMenu) in itemChoose.menu" v-bind:key="itemMenu">
                                                    <td>
                                                        <i class="fa" :class="(itemMenu.icon.length === 0 ?'fa-indent':itemMenu.icon)"></i>
                                                    </td>
                                                    <td>
                                                        <div class="flex-fill">
                                                            <div class="fs-13px text-truncate w-250px">{{ itemMenu.name }}</div>
                                                            <div class="fs-12px text-white text-opacity-50">{{ itemMenu.describe }}</div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                
                                            </template>
                                        </template>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="card-arrow">
                        <div class="card-arrow-top-left"></div>
                        <div class="card-arrow-top-right"></div>
                        <div class="card-arrow-bottom-left"></div>
                        <div class="card-arrow-bottom-right"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <AddComponents 
        @turnOffAddHeader="turnOnOffAdd"
        v-if="this?.popup?.add !== false"
        :id="this?.popup?.editHeaderId ?? null"/>
    <AddMenuComponents
        @callback="turnOnOffAddMenu"
        v-if="this?.popup?.addMenu !== false"
        :header_id="this?.popup?.headerId ?? null"
        :id="this?.popup?.editId ?? null"/>
    <deleteComponent 
        v-if="this?.popup?.deleteId !== null"
        @callback="PopupDelete"
        :id="this.popup.deleteId"
        :url="getLinkDelete()"/>
    <deleteComponent 
        v-if="this?.popup?.deleteMenuId !== null"
        @callback="PopupDeleteMenu"
        :id="this.popup.deleteMenuId"
        :url="getLinkDeleteMenu()"/>
    <ScrollToTop />
</template>

<script>
    import Menu from '../components/Menu.vue';
    import Header from '../components/Header.vue';
    import ScrollToTop from '../components/ScrollToTop.vue';
    import Breadcrumb from '../components/Breadcrumb.vue';
    
    import utils from '../../helper/utils';
    import request from '../../helper/request';

    import AddComponents from './Components/AddHeader.vue'
    import AddMenuComponents from './Components/AddMenu.vue'
    import deleteComponent from '../components/Popup/Delete.vue'

    export default {
        name: 'ModuleList',
        components: {
            Header,
            Menu,
            ScrollToTop,
            AddComponents,
            AddMenuComponents,
            Breadcrumb,
            deleteComponent
        },
        data() {
            return {
                error: null,
                role_id: false,
                data: [],
                loading: false,
                chooses: [],
                countDown: 0,
                treeMenu: [],
                messageSuccess: null,
                breadcrumbs: [{'link': 'MenuIndex', 'text': 'Menu'}],
                popup: {
                    add: false,
                    addMenu: false,
                    editHeaderId: null,
                    headerId: null,
                    deleteId: null,
                    deleteMenuId: null,
                    editId: null
                }
            };
        },
        methods: {
            resetPopup: function() {
                this.popup =  {
                    add: false,
                    addMenu: false,
                    editHeaderId: null,
                    headerId: null,
                    deleteId: null,
                    deleteMenuId: null,
                    editId: null
                };
            },
            loadData: function() {
                request.get(`${process.env.VUE_APP_BASE_API}/v1/api/administrator/menu/display`)
                    .then((resp) => {
                        if(resp.status_code === 200) {
                            this.data = resp?.response?.data ?? [];
                        } else this.error = utils.responseError(resp, {});
                    }).catch((err) => {
                        console.log(err);
                        this.$router.push({ name: 'ErrorServerError'});
                    });
            },
            PopupDelete: function(id, result = false) {
                this.popup.deleteId = id;
                if(result === true) {
                    this.loadData();
                }
            },
            editHeader: function(editHeaderId) {
                this.popup.editHeaderId = editHeaderId;
                this.turnOnOffAdd(true, false);
            },
            editMenu: function(editId) {
                this.popup.editId = editId;
                this.turnOnOffAddMenu(true, null, false);
            },
            turnOnOffAdd: function(type, loaddata = false) {
                this.popup.add = type;
                if(loaddata === true) this.loadData();
            },
            turnOnOffAddMenu: function(type, headerId, loaddata = false) {
                this.popup.addMenu = type;
                this.popup.headerId = headerId;
                if(loaddata === true) this.loadData();
            },
            getLinkDelete: function() {
                return `${process.env.VUE_APP_BASE_API}/v1/api/administrator/menu/header/${this.popup.deleteId}`;
            },
            PopupDeleteMenu: function(id, result = false) {
                this.popup.deleteMenuId = id;
                if(result === true) {
                    this.loadData();
                }
            },
            getLinkDeleteMenu: function() {
                return `${process.env.VUE_APP_BASE_API}/v1/api/administrator/menu/${this.popup.deleteMenuId}`;
            },
            renderMenuForReview: function() {
                let result = [];
                for(const id of this.chooses) {
                    for(const item of this.data) {
                        if(item.type === 'HEADER') {
                            for(const menu of item.menu) {
                                if(menu.id === id) {
                                    let exists = false;

                                    for(let i = 0; i < result.length; i++) {
                                        if(result[i].id === item.id) {
                                            exists = true;
                                            result[i].menu.push({
                                                type: 'MENU',
                                                id: id,
                                                name: menu.lable,
                                                icon: menu.icon
                                            });
                                            break;
                                        }
                                    }

                                    if(exists === false) {
                                        result.push({
                                            type: 'HEADER',
                                            id: item.id,
                                            name: item.name,
                                            describe: item.describe,
                                            menu: [{
                                                type: 'MENU',
                                                id: id,
                                                name: menu.lable,
                                                describe: menu.describe,
                                                icon: menu.icon
                                            }]
                                        });
                                    }
                                }
                            }
                        } else  {
                            if(item.id === id) {
                                result.push({
                                    type: 'MENU',
                                    id: id,
                                    name: item.lable,
                                    describe: item.describe,
                                    icon: item.icon
                                });
                            }
                        }
                    }
                }
                this.treeMenu = result;
            },
            handleAddToRole: function() {
                request.put(`${process.env.VUE_APP_BASE_API}/v1/api/administrator/roles/${this.role_id}`, {
                    menu: this.treeMenu
                })
                    .then((resp) => {
                        if(resp.status_code === 200) {
                            this.countDown = 3;
                            this.messageSuccess = `Thêm menu thành công. Quay lại màn hình phân quyền trong ${this.countDown}s`;
                            this.countDownTimer();
                        } else this.error = utils.responseError(resp, {});
                    }).catch((err) => {
                        console.log(err);
                        this.$router.push({ name: 'ErrorServerError'});
                    });
            },
            countDownTimer: function() {
                if (this.countDown > 0) {
                    setTimeout(() => {
                        this.countDown -= 1
                        this.messageSuccess = `Thêm menu thành công. Quay lại màn hình phân quyền trong ${this.countDown}s`;
                        this.countDownTimer()
                    }, 1000)
                } else this.$router.push({ name: 'Roleindex'});
            },
            showData: function() {
                if(this.data !== null && this.data.length > 0) return true;
                else return false;
            }
        },
        mounted: function () {
            this.loadData();
            if(this?.$route?.query?.role_id) {
                this.breadcrumbs =  [
                    {'link': 'Roleindex', 'text': 'Phân quyền'},
                    {'link': 'MenuIndex', 'text': 'Menu'}
                ];
                this.role_id = this.$route.query.role_id;


                request.get(`${process.env.VUE_APP_BASE_API}/v1/api/administrator/roles/${this.role_id}`)
                    .then((resp) => {
                        if(resp.status_code === 200) {
                            const result = [];
                            const menu = resp?.response?.detail?.menu ?? [];
                            for(const item of menu) {
                                if(item.type === 'HEADER') {
                                    for(const itemChildren of item.menu) {
                                        result.push(itemChildren.id);
                                    }
                                } else result.push(item.id);
                            }
                            console.log(result);
                            this.chooses = result;
                        } else this.error = utils.responseError(resp, {});
                    }).catch((err) => {
                        console.log(err);
                        this.$router.push({ name: 'ErrorServerError'});
                    });
            } else {
                this.role_id = null;
            }
        },
		created() {
            document.body.classList.remove('pace-top');
            document.getElementById('app').classList.remove('app-full-height','app-without-header');
        }
    }
</script>