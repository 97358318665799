<template>
    <div class="login">
        <div class="login-content">
            <Form @submit="onSubmit" :validation-schema="schema">
                <h1 class="text-center">Đăng Nhập</h1>
                <div class="text-white text-opacity-50 text-center mb-4">
                    Hệ thống quản lý YTPlus
                </div>
                <div class="mb-3" v-if="this.error">
                    <alert :type="this.error.type" :message="this.error.message"/>
                </div>
                <div class="mb-3">
                    <label class="form-label">Tài Khoản <span class="text-danger">*</span>
                    </label>
                    <TextInput
                        name="username"
                        type="text"
                        placeholder="Tài Khoản"
                        v-bind:readonly="working"
                        className="form-control-lg bg-white bg-opacity-5"
                    />
                </div>
                <div class="mb-3">
                    <div class="d-flex">
                        <label class="form-label">Mật khẩu <span class="text-danger">*</span>
                        </label>
                    </div>
                    <TextInput
                        name="password"
                        type="password"
                        placeholder="Mật khẩu"
                        v-bind:readonly="working"
                        className="form-control-lg bg-white bg-opacity-5"
                    />
                </div>
                <button type="submit" v-bind:disabled="working" class="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3">Đăng nhập</button>
                <div class="text-center text-white text-opacity-50">
                    Liên hệ admin nếu bạn chưa có tài khoản
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import { Form } from 'vee-validate';
    import alert from '../components/Alert.vue';
    import TextInput from '../components/Form/Input.vue';
    import * as yup from 'yup';
    import request from '../../helper/request';
    import utils from '../../helper/utils';

    export default {
        name: 'Login',
        metaInfo() {
            return {
                title: 'Đăng nhập - YTPlus'
            }
        },
        data() {
            const schema = {
                username: yup.string().required().max(200),
                password: yup.string().required().min(6).max(200)
            };
            return {
                schema,
                error: null,
                working: false
            };
        },
        created() {
            document.body.classList.add('pace-top');
            document.getElementById('app').classList.add('app-full-height','app-without-header');
        },
        components: {
            Form,
            TextInput,
            alert
        },
        methods: {
            async onSubmit (values) {
                const keys = {
                    username: 'Tài khoản',
                    password: 'Mật khẩu'
                }

                this.error = null;
                this.working = true;
                const response = await request.post(`${process.env.VUE_APP_BASE_API}/v1/api/administrator/user/log-in`, JSON.stringify(values, null, 2));
                if(response.status === 'success') {
                    this.$store.dispatch('user/login', response.response.user);
                    this.$router.push({ name: 'Dashboard'});
                }
                else this.error = utils.responseError(response, keys);
                this.working = false;
            }
        },
        meta: { bodyClass: 'bg-light' }
    }
</script>