<template>
    <Display 
        status_code="500"
        message="500 Internal Server Error"
    />
</template>

<script>
    import Display from './components/display.vue';
    export default {
        name: 'Page Not Found 500',
        components: {
            Display
        },
        created() {
            document.body.classList.add('pace-top');
            document.getElementById('app').classList.add('app-full-height','app-without-header');
        },
    }
</script>