<template>
    <select class="form-select"
        :name="name"
        :id="name"
        :readonly="readonly"
        @input="handleChange"
        @blur="handleBlur"
        :class="classObject"
        @change="changeValue"
    >

        <option v-if="placeholder.length > 0" :selected="true">{{ placeholder }}</option>
        <option v-for="(value, key) in getItems"
            v-bind:key="key" 
            :value="Number.isInteger(key)?value:key"
            :selected="key===value?true:false">
                {{ value }}
        </option>
    </select>

    <div :class="{ 'invalid-feedback': !!errorMessage, 'valid-feedback': meta.valid }" v-show="errorMessage || meta.valid">
        {{ errorMessage || successMessage }}
    </div>
</template>

<script>
import { toRef } from "vue";
import { useField } from "vee-validate";

export default {
    data() {
        return {
            change:false
        };
    },
    props: {
        name: {
            type: String,
            required: true,
        },
        items: {
            type: Array,
        },
        value: {
            type: String,
            default: "",
        },
        successMessage: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            default: "",
        },
        readonly: {
            type:Boolean,
            default: false
        },
        className: {
            type:String,
            default: ""
        }
    },
    setup(props) {
        const name = toRef(props, "name");
        const {
            value: inputValue,
            errorMessage,
            handleBlur,
            handleChange,
            meta,
        } = useField(name, undefined, {
            initialValue: props.value,
        });

        return {
            handleChange,
            handleBlur,
            errorMessage,
            inputValue,
            meta,
        };
    },
    methods: {
        changeValue() {
            this.change = true;
        }
        
    },
    computed: {
        classObject() {
            let result = this.className;
            if(this.change) {
                if(this.errorMessage) result += ' is-invalid ';
                else if(this.meta.valid) result += ' is-valid ';
            }
            return result;
        },
        getItems() {
            let results = {};
            this.items.forEach((v) => {
                results[v.key] = `${v.value}` ;
            });
            return results;
        }
    }
};
</script>