<template>
    <div class="card mb-3">
        <div class="card-body">
            <div class="d-flex fw-bold small mb-3">
                <span class="flex-grow-1">Danh sách đang giao dịch</span>
            </div>
            <div class="table-responsive">
                <table class="table table-striped table-borderless mb-2px small text-nowrap">
                    <tbody>
                        <template v-if="lists === null || lists.length === 0">
                            <tr>
                                <td class="text-center">
                                    Chưa có gì để hiển thị
                                </td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr v-for="(item) in lists"  v-bind:key="item">
                                <td>
                                    <span class="d-flex align-items-center">
                                        <i class="bi bi-circle-fill fs-6px text-white text-opacity-25 me-2"></i>
                                        {{ item.symbol }}
                                    </span>
                                </td>
                                <td class="text-center">
                                    {{ item.mass.remaining }}
                                </td>
                                <td class="text-opacity-75 fw-500 text-center">
                                    {{ formatCurrency(item.price.cost) }}
                                </td>
                                <td class="text-opacity-75 fw-500 text-center text-warning">
                                    {{ formatCurrency(item.price.market) }}
                                </td>
                                <td class="text-opacity-75 fw-500 text-center">
                                    <template v-if="item.price.difference !== 0">
                                        <span class="text-teal"> {{ formatCurrency(item.price.difference) }}</span>
                                    </template>
                                    <template v-else>
                                        <span class="text-white-50">-</span>
                                    </template>
                                </td>
                                <td class="text-opacity-75 fw-500 text-center">
                                    <template v-if="item.price.percent == 0">
                                        <span class="text-white text-opacity-50">0%</span>
                                    </template>
                                    <template v-else>
                                        <span class="text-danger" v-if="item.price.percent < 0">{{item.price.percent}}%</span>
                                        <span class="text-success" v-else>{{item.price.percent}}%</span>
                                    </template>
                                </td>
                                <td class="text-white text-opacity-75 fw-500 text-end">
                                    <span class="text-success" style="min-height: 18px" v-if="item.last_money > 0">
                                        {{ formatCurrency(item.last_money) }}
                                    </span>
                                    <span class="text-danger" style="min-height: 18px" v-else>
                                        {{ formatCurrency(item.last_money) }}
                                    </span>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="card-arrow">
            <div class="card-arrow-top-left"></div>
            <div class="card-arrow-top-right"></div>
            <div class="card-arrow-bottom-left"></div>
            <div class="card-arrow-bottom-right"></div>
        </div>
    </div>
</template>

<script>
import request from '../../../helper/request';
import util from '../../../helper/utils';
export default {
    name: 'TransactionListStockWarehouse',
    data() {
        return {
            lists: null,
            PaginateMeta: null
        };
    },
    methods: {
        formatCurrency: function(value) {
            return util.formatCurrency(value);
        },
        async loadData(page = 1) {
            const response = await request.get(`${process.env.VUE_APP_BASE_API}/v1/api/administrator/finance/stock/warehouse?page=${page}`)
                .then((data) => data);
            if(response.status === 'success') {
                this.lists = response?.response?.data??null;
                this.PaginateMeta = response?.response?.meta??null;
            } else {
                this.lists = null;
            }
        },

    },
    mounted: async function () {
        this.loadData();
    },
}
</script>
