import { createRouter, createWebHistory } from 'vue-router';
import store from '../stores';
import request from '../helper/request';

// Route Auth
import AuthLogin from '../views/Auth/Login.vue'

// Route Dashboard
import Dashboard from '../views/Dashboard.vue'

// Route Finance
import FinanceStock from '../views/Finance/stock.vue'

// Route Finance
import Moduleindex from '../views/Modules/index.vue'

// Error
import ErrorPageNotFound from '../views/Errors/404.vue'
import ErrorServerError from '../views/Errors/500.vue'

// Route Role
import Roleindex from '../views/Role/index.vue'

// Route Role
import MenuIndex from '../views/Menu/index.vue';

const router = createRouter({
	history: createWebHistory(),
	routes: [
		{ path: '/', name: 'Dashboard', component: Dashboard, props:true },
		{ path: '/finance/stock', name: 'FinanceStock', component: FinanceStock, props:true },
		{ path: '/login', name: 'AuthLogin', component: AuthLogin },
		{ path: '/modules', name: 'Moduleindex', component: Moduleindex },
		{ path: '/roles', name: 'Roleindex', component: Roleindex },
		{ path: '/menus', name: 'MenuIndex', component: MenuIndex },
		{ path: '/error/404', name: 'ErrorPageNotFound', component: ErrorPageNotFound },
		{ path: '/error/500', name: 'ErrorServerError', component: ErrorServerError }
	]
})

router.beforeEach(async (to, from, next) => {
	const routeStatic = ['/login'];
	if(!routeStatic.includes(to.path)) {
		const isAuth = store.getters['user/getAuth'];
		if(!isAuth) {
			store.dispatch('user/logout');
			return next('/login');
		}
		else {
			const currentUser = await request.get(`${process.env.VUE_APP_BASE_API}/v1/api/administrator/user/me`)
							.then((res) => res);
			if(currentUser?.status === 'error') {
				store.dispatch('user/logout');
				return next('/login');
			}
			else if(currentUser?.status === 'success') {
				store.dispatch('user/setInfor', currentUser.response.user);
			}
		}
	}
	next();
});

export default router

