<template>
    <ul class="breadcrumb">
        <template v-for="(value, key) in getItems"  v-bind:key="key">
            <li class="breadcrumb-item" v-if="value.link">
                <router-link :to="{ name: value.link}">
                    {{ value.text }}
                </router-link>
            </li>
            <li class="breadcrumb-item active" v-else>{{ value.text }}</li>
        </template>
    </ul>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
        }
    },
    computed: {
        getItems() {
            let results = [{link: 'Dashboard', text: 'YTPlus'}];
            this.items.forEach((v) => {
                results.push(v);
            });
            return results;
        }
    }
};
</script>