<template>
    <Header/>
    <Menu />
    <div id="content" class="app-content">
        <div class="d-flex align-items-center mb-md-3 mb-2">
            <div class="flex-fill">
                <Breadcrumb :items="breadcrumbs" />
                <h1 class="page-header mb-0">
                    {{ this.title }}
                </h1>
            </div>
            <div class="ms-auto">
                <button @click="aysncData()" class="btn btn-outline-theme" v-if="this.role_id === null">
                    <i class="fa fa-refresh me-1" v-if="this.loading === false"></i> 
                    <div class="spinner-border spinner-border-sm" v-else style="vertical-align:-0.2em"></div>
                    Đồng bộ
                </button>
                <template v-else>
                    <div class="columns columns-right btn-group float-right">
                        <button class="btn btn-outline-theme" type="button" name="Add" aria-label="Add" title="Add" @click="addToRole()">
                            <i class="fa fa-plus-circle me-1" v-if="this.loading === false"></i> 
                            <div class="spinner-border spinner-border-sm" v-else style="vertical-align:-0.2em"></div>
                            Thêm vào phân quyền
                        </button>
                        <div class="keep-open btn-group" title="Columns">
                            <button class="btn btn-outline-theme dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-label="Columns" title="Columns" aria-expanded="false">
                            </button>
                            <div class="dropdown-menu dropdown-menu-right" style="">
                                <button class="dropdown-item" @click="addAll()">
                                    Chọn tất cả
                                </button>
                                <button class="dropdown-item" @click="clearAll()">
                                    Xoá tất cả
                                </button>
                                <button class="dropdown-item" @click="refetch()">
                                    Reset
                                </button>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <div class="row" v-if="this.error">
            <div class="col-lg-12">
                <alert :type="this.error.type" :message="this.error.message"/>
            </div>
        </div>
        
        <div class="row">
            <div class="col-12">
                <div class="alert alert-success" v-if="messageSuccess!==null">
                    {{ messageSuccess }}
                </div>
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex fw-bold small mb-3">
                            <span class="flex-grow-1">Danh sách module</span>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-striped align-middle">
                                <tbody>
                                    <template v-for="(group) in data" v-bind:key="group">
                                        <tr class="table-success" :data-id="group.id">
                                            <td colspan="4">
                                                <div class="flex-fill">
                                                    <div class="fs-13px text-truncate w-250px">{{ group.name }}</div>
                                                    <div class="fs-12px text-white text-opacity-50">{{ group.describe }}</div>
                                                </div>
                                            </td>
                                            <td v-if="this.role_id === null">
                                                <a href="javascript:void(0)" @click="turnOnOffEditGroup(group.id, false)" class="ms-auto text-decoration-none" data-toggle="card-expand">
                                                    <i class="bi bi-pencil-square"></i>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr v-for="(module) in group.detail" v-bind:key="module">
                                            <td v-if="this.role_id !== null" style="width:1%">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" 
                                                        :id="('choose_' + module.id)" 
                                                        :value="module.id" 
                                                        style="margin-bottom: 0px;" 
                                                        :disabled="module.is_public"
                                                        v-model="chooses">
                                                </div>
                                            </td>
                                            <td>
                                                <div class="flex-fill">
                                                    <div class="fs-13px text-truncate w-250px">{{module.name}}</div>
                                                    <div class="fs-12px text-white text-opacity-50">{{module.describe}}</div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="flex-1 text-break">
                                                    <div class="w-250px">{{module.endpoint.substring(5)}}</div>
                                                    <div class="mb-1">
                                                        <span  v-if="module.method.toUpperCase() === 'DELETE'" class="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-warning bg-opacity-25 text-warning text-opacity-75 pt-5px">
                                                            DELETE
                                                        </span>
                                                        <span  v-if="module.method.toUpperCase() === 'GET'" class="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-info bg-opacity-25 text-info text-opacity-75 pt-5px">
                                                            GET
                                                        </span>
                                                        <span  v-if="module.method.toUpperCase() === 'POST'" class="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-primary bg-opacity-25 text-primary text-opacity-75 pt-5px">
                                                            POST
                                                        </span>
                                                        <span  v-if="module.method.toUpperCase() === 'PUT'" class="fs-9px fw-500 lh-1 d-inline-block rounded-0 badge bg-success bg-opacity-25 text-success text-opacity-75 pt-5px">
                                                            PUT
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <table class="mb-2 w-250px">
                                                    <tbody><tr>
                                                        <td class="pe-3">Controller:</td>
                                                        <td class="text-white text-opacity-75 fw-500">{{module.controller.substring(2)}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="pe-3">Function:</td>
                                                        <td class="text-white text-opacity-75 fw-500">{{module.function}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="pe-3 text-nowrap">Validator:</td>
                                                        <td class="text-white text-opacity-75 fw-500">{{module.validate}}</td>
                                                    </tr>
                                                </tbody></table>
                                            </td>
                                            <td v-if="this.role_id === null">
                                                <div class="form-check form-switch mb-3 w-150px">
                                                    <input type="checkbox" class="form-check-input" :checked="!module.is_block" disabled="" :id="('block_' + module.id)">
                                                    <label class="form-check-label" :for="('block_' + module.id)">Hoạt động</label>
                                                </div>
                                                <div class="form-check form-switch mb-3 w-150px">
                                                    <input type="checkbox" class="form-check-input" :checked="module.is_public" disabled="" :id="('public_' + module.id)">
                                                    <label class="form-check-label" :for="('public_' + module.id)">Công khai</label>
                                                </div>
                                                <div class="form-check form-switch mb-3 w-150px">
                                                    <input type="checkbox" class="form-check-input" :checked="module.is_admin" disabled="" :id="('admin_' + module.id)">
                                                    <label class="form-check-label" :for="('admin_' + module.id)">Chi admin</label>
                                                </div>
                                            </td>
                                            <td v-if="this.role_id === null">
                                                <a href="javascript:void(0)" @click="turnOnOffEdit(module.id, false)" class="text-decoration-none text-white"><i class="bi bi-pencil-square"></i></a>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>

                    </div>
                    <div class="card-arrow">
                        <div class="card-arrow-top-left"></div>
                        <div class="card-arrow-top-right"></div>
                        <div class="card-arrow-bottom-left"></div>
                        <div class="card-arrow-bottom-right"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <EditModule
            @turnOffEdit="turnOnOffEdit"
            v-if="this?.popup?.id !== null"
            :id="this.popup.id"/>
    <EditGroup 
            @turnOffEditGroup="turnOnOffEditGroup"
            v-if="this?.popup?.groupId !== null"
            :id="this.popup.groupId"/>
    <ScrollToTop />
</template>

<script>
    import Menu from '../components/Menu.vue';
    import alert from '../components/Alert.vue';
    import Header from '../components/Header.vue';
    import ScrollToTop from '../components/ScrollToTop.vue';
    import Breadcrumb from '../components/Breadcrumb.vue';

    // Insert
    import EditGroup from './Components/editGroup.vue'
    import EditModule from './Components/editModule.vue'

    import request from '../../helper/request';
    import utils from '../../helper/utils';

    export default {
        name: 'ModuleList',
        components: {
            Header,
            Menu,
            ScrollToTop,
            alert,
            EditGroup,
            EditModule,
            Breadcrumb
        },
        data() {
            return {
                error: null,
                title: 'Chức năng hệ thống',
                breadcrumbs: [{'link': 'Moduleindex', 'text': 'Chức năng'}],
                role_id: null,
                loading: false,
                countDown: 0,
                messageSuccess: null,
                data: [],
                chooses: [],
                selected: [],
                popup: {
                    groupId: null,
                    id: null
                }
            };
        },
        methods: {
            turnOnOffEditGroup: function(id, loadData) {
                this.popup.groupId = id;
                if(loadData === true) {
                    this.loadData();
                }
            },
            turnOnOffEdit: function(id, loadData) {
                this.popup.id = id;
                if(loadData === true) {
                    this.loadData();
                }
            },
            loadData: function() {
                request.get(`${process.env.VUE_APP_BASE_API}/v1/api/administrator/modules`)
                    .then((resp) => {
                        if(resp.status_code === 200) {
                            this.data = resp?.response?.data ?? [];
                            this?.data?.forEach((item) => {
                                item?.detail?.forEach((element) => {
                                    if(element?.is_public === true) {
                                        this.chooses.push(element.id);
                                    }
                                });
                            })
                        } else this.error = utils.responseError(resp, {});
                    }).catch((err) => {
                        console.log(err);
                        this.$router.push({ name: 'ErrorServerError'});
                    });
            },
            aysncData: function() {
                this.loading = true;
                request.post(`${process.env.VUE_APP_BASE_API}/v1/api/administrator/modules`, {})
                    .then((resp) => {
                        if(resp.status_code === 200) {
                            this.loadData();
                        } else this.error = utils.responseError(resp, {});
                        this.loading = false; 
                    }).catch((err) => {
                        console.log(err);
                        this.loading = false; 
                        this.$router.push({ name: 'ErrorServerError'});
                    });
            },
            addToRole: function() {
                request.put(`${process.env.VUE_APP_BASE_API}/v1/api/administrator/roles/${this.role_id}`, {
                    role_ids: this.chooses
                })
                    .then((resp) => {
                        if(resp.status_code === 200) {
                            const type = this?.$route?.query?.type || 'edit';
                            this.countDown = 3;
                            if(type === 'edit') {
                                this.messageSuccess = `Cập nhật module thành công. Quay lại màn hình phân quyền trong ${this.countDown}s`;
                            } else {
                                this.messageSuccess = `Thêm module thành công. Tự động chuyển đến trang menu trong ${this.countDown}s`;
                            }
                            this.countDownTimer();
                        } else this.error = utils.responseError(resp, {});
                    }).catch((err) => {
                        console.log(err);
                        this.$router.push({ name: 'ErrorServerError'});
                    });
            },
            countDownTimer: function() {
                if (this.countDown > 0) {
                    setTimeout(() => {
                        this.countDown -= 1
                        const type = this?.$route?.query?.type || 'edit';
                        if(type === 'edit') {
                            this.messageSuccess = `Cập nhật module thành công. Quay lại màn hình phân quyền trong ${this.countDown}s`;
                        } else {
                            this.messageSuccess = `Thêm module thành công. Tự động chuyển đến trang menu trong ${this.countDown}s`;
                        }
                        this.countDownTimer()
                    }, 1000)
                } else {
                    const type = this?.$route?.query?.type || 'edit';
                    if(type === 'edit') {
                        this.$router.push({ name: 'Roleindex'});
                    } else {
                        this.$router.push({ name: 'MenuIndex',query :{role_id: this.$route.query.role_id, type: 'add'}});
                    }
                }
            },
            addAll: function() {
                this.chooses = [];
                this.data?.forEach((v) => {
                    v?.detail?.forEach((d) => {
                        this.chooses.push(d.id);
                    })
                })
            },
            clearAll: function() {
                this.chooses = [];
                this.data?.forEach((v) => {
                    v?.detail?.forEach((d) => {
                        if(d.is_public === true) this.chooses.push(d.id);
                    })
                })
            },
            refetch: function() {
                this.chooses = [];
                this.data?.forEach((v) => {
                    v?.detail?.forEach((d) => {
                        if(d.is_public === true) this.chooses.push(d.id);
                    })
                });

                this.chooses = this.chooses.concat(this.selected);
            }
        },  
        mounted: function () {
            this.loadData();

            if(this?.$route?.query?.role_id) {
                this.breadcrumbs =  [
                    {'link': 'Roleindex', 'text': 'Phân quyền'},
                    {'link': 'Moduleindex', 'text': 'Chức năng'}
                ];
                this.role_id = this.$route.query.role_id;

                request.get(`${process.env.VUE_APP_BASE_API}/v1/api/administrator/roles/${this.role_id}`)
                    .then((resp) => {
                        if(resp.status_code === 200) {
                            this.title = resp?.response?.detail?.name ?? 'Chức năng hệ thống';
                            resp?.response?.detail?.role_id?.forEach((id) => {
                                this.chooses.push(id);
                                this.selected.push(id);
                            });
                        } else this.error = utils.responseError(resp, {});
                    }).catch((err) => {
                        console.log(err);
                        this.$router.push({ name: 'ErrorServerError'});
                    });
            }
        },
		created() {
            document.body.classList.remove('pace-top');
            document.getElementById('app').classList.remove('app-full-height','app-without-header');
        }
    }
</script>
