<template>
    <input
        :name="name"
        :id="name"
        :type="type"
        ref="type"
        :value="inputValue"
        :placeholder="placeholder"
        :readonly="readonly"
        @input="handleChange"
        @blur="handleBlur"
        :class="classObject"
        v-on:keyup="keyupChange"
        :disabled="readonly"
    />

    <div :class="{ 'invalid-feedback': !!errorMessage, 'valid-feedback': meta.valid }" v-show="errorMessage || meta.valid">
        {{ errorMessage || successMessage }}
    </div>
</template>

<script>
import { toRef } from "vue";
import { useField } from "vee-validate";

export default {
    data() {
        return {
            change:false
        };
    },
    props: {
        type: {
            type: String,
            default: "text",
        },
        value: {
            type: String,
            default: "",
        },
        name: {
            type: String,
            required: true,
        },
        successMessage: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            default: "",
        },
        readonly: {
            type:Boolean,
            default: false
        },
        className: {
            type:String,
            default: ""
        }
    },
    setup(props) {
        const name = toRef(props, "name");
        const {
            value: inputValue,
            errorMessage,
            handleBlur,
            handleChange,
            meta,
        } = useField(name, undefined, {
            initialValue: props.value,
        });

        return {
            handleChange,
            handleBlur,
            errorMessage,
            inputValue,
            meta,
        };
    },
    methods: {
        keyupChange() {
            this.change = true;
        }
        
    },
    computed: {
        classObject() {
            let result = `form-control ${this.className}`;
            if(this.change) {
                if(this.errorMessage) result += ' is-invalid ';
                else if(this.meta.valid) result += ' is-valid ';
            }
            return result;
        }
    }
};
</script>