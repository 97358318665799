<template>
    <div class="modal modal-cover fade show" style="display: block;" aria-modal="true" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header p-0 py-3">
                    <h3 class="modal-title">Chỉnh sửa chức năng</h3>
                    <button type="button" class="btn-close" @click="turnOffEdit"></button>
                </div>
                <div class="modal-body p-0">
                    <div class="card">
                        <div class="card-body">
                            <template v-if="error !== null">
                                <Alert 
                                    :type="error.type" 
                                    :message="error.message"/>
                            </template>
                            <template v-else>
                                <div class="alert alert-success" v-if="messageSuccess!==null">
                                    {{ messageSuccess }}
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">Tên module</label>
                                    <input 
                                        id="name" 
                                        name="name" 
                                        type="text" 
                                        v-model="name" 
                                        class="form-control"
                                        :class="validator.name !== null && validator.name.length > 0 ? 'is-invalid': ''"/>
                                    <div class="invalid-feedback" v-if="validator.name !== null && validator.name.length > 0">{{ validator.name }}</div>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">Key</label>
                                    <input 
                                        id="name_system" 
                                        name="name_system" 
                                        type="text" 
                                        v-model="name_system" 
                                        class="form-control"
                                        :class="validator.name_system !== null && validator.name_system.length > 0 ? 'is-invalid': ''"/>
                                    <div class="invalid-feedback" v-if="validator.name_system !== null && validator.name_system.length > 0">{{ validator.name_system }}</div>
                                </div>

                                
                                <div class="mb-3">
                                    <label class="form-label">Mô tả</label>
                                    <textarea 
                                        class="form-control" 
                                        id="describe" 
                                        name="describe" 
                                        v-model="describe" 
                                        rows="3"
                                        :class="validator.describe !== null && validator.describe.length > 0 ? 'is-invalid': ''"
                                    ></textarea>
                                    <div class="invalid-feedback" v-if="validator.describe !== null && validator.describe.length > 0">{{ validator.describe }}</div>
                                </div>
                                <div class="form-check form-switch mb-3">
                                    <input type="checkbox" id="isBlock" class="form-check-input" v-model="is_block">
                                    <label class="form-check-label" for="isBlock">Trạng thái hoạt động</label>
                                </div>
                                <div class="form-check form-switch mb-3">
                                    <input type="checkbox" id="isPublic" class="form-check-input" v-model="is_public">
                                    <label class="form-check-label" for="isPublic">Module công khai</label>
                                </div>
                                <div class="form-check form-switch mb-0">
                                    <input type="checkbox" id="isAdmin" class="form-check-input" v-model="is_admin">
                                    <label class="form-check-label" for="isAdmin">Chỉ được dùng bời tài khoản admin</label>
                                </div>
                            </template>
                        </div>
                        <div class="card-footer bg-none" style="z-index: 9;">
                            <button type="submit" class="btn btn-outline-theme" @click="onSubmit">Lưu dữ liệu</button>
                            <button type="button" class="btn btn-outline-danger ms-1" @click="turnOffEdit">Huỷ</button>
                        </div>
                        <div class="card-arrow">
                            <div class="card-arrow-top-left"></div>
                            <div class="card-arrow-top-right"></div>
                            <div class="card-arrow-bottom-left"></div>
                            <div class="card-arrow-bottom-right"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-backdrop fade show"></div>
</template>

<script>
    import Helper from '../../../helper/utils';
    import request from '../../../helper/request';
    import Alert from '../../components/Alert.vue';

    export default {
        name: 'EditGroup',
        emits: ["turnOffEdit"],
        data() {
            return {
                error: null,
                messageSuccess: null,
                name: null,
                describe: null,
                name_system:null,
                is_block:false,
                is_public:false,
                is_admin:false,
                validator: {
                    name: null,
                    describe: null,
                    name_system: null,
                }
            };
        },  
        props: {
            id: {
                type: String,
                default: null,
            }
        }, 
        components: {
            Alert
        },
        methods: {
            onSubmit: function () {
                request.put(`${process.env.VUE_APP_BASE_API}/v1/api/administrator/modules/${this.id}`, {
                    name: this.name,
                    describe: this.describe,
                    name_system: this.name_system,
                    is_block: this.is_block,
                    is_public: this.is_public,
                    is_admin: this.is_admin,
                })
                    .then((data) => {
                        const keys = {
                            name: 'Tên nhóm',
                            describe: 'Mô tả',
                            name_system: 'Từ khoá',
                            is_block: 'Hoạt động'
                        };

                        Object.keys(this.validator).forEach((v) => {
                            this.validator[v] = null;
                        })
                        
                        if(data.status === 'success') {
                            this.countDown = 5;
                            this.messageSuccess = `Thêm dữ liệu thành công. màn hình sẽ tự tắt sau ${this.countDown}s`;
                            this.countDownTimer();
                        } else {
                            const err = Helper.responseError(data, keys);
                            if(err.reason === 'Validator') {
                                const messages = JSON.parse(err.message);
                                messages.forEach(element => {
                                    this.validator[element.key] = element.message;
                                });
                            } else {
                                this.error = err;
                            }
                        }
                    });
            },
            countDownTimer: function() {
                if (this.countDown > 0) {
                    setTimeout(() => {
                        this.countDown -= 1
                        this.messageSuccess = `Thêm dữ liệu thành công. màn hình sẽ tự tắt sau ${this.countDown}s`;
                        this.countDownTimer()
                    }, 1000)
                } else this.turnOffEdit(true);
            },
            turnOffEdit: function(loaddata = null) {
                this.$emit('turnOffEdit', null, loaddata);
            }
        },
        mounted: function () {
            request.get(`${process.env.VUE_APP_BASE_API}/v1/api/administrator/modules/${this.id}`)
                .then((resp) => {
                    if(resp.status_code === 200) {
                        this.name = resp?.response?.detail?.name ?? null;
                        this.name_system = resp?.response?.detail?.key ?? null;
                        this.describe = resp?.response?.detail?.describe ?? null;
                        this.is_block = !(resp?.response?.detail?.is_block ?? false);
                        this.is_public = resp?.response?.detail?.is_public ?? false;
                        this.is_admin = resp?.response?.detail?.is_admin ?? false;
                    } else this.error = Helper.responseError(resp, {});
                }).catch((err) => {
                    console.log(err);
                    this.$router.push({ name: 'ErrorServerError'});
                });
        },
    }
</script>