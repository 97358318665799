<template>
    <Header/>
    <Menu />
    <!-- BEGIN #content -->
    <div id="content" class="app-content">
        <ul class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">BREADCRUMB</a></li>
            <li class="breadcrumb-item active">HOME PAGE</li>
        </ul>
        
        <h1 class="page-header">
            Starter Page <small>page header description goes here...</small>
        </h1>
        
        <p>
            Start build your page here
        </p>
    </div>
    <!-- END #content -->
    <ScrollToTop />
</template>

<script>

    import Header from './components/Header.vue';
    import Menu from './components/Menu.vue';
    import ScrollToTop from './components/ScrollToTop.vue';
    
    export default {
        name: 'IndexDashboard',
        components: {
            Header,
            Menu,
            ScrollToTop
        },
        metaInfo() {
            return {
                title: 'YTPlus',
                meta: [
                    { name: 'description', content: 'Nền tảng tra cứu network và Quản lý - Theo dõi đa kênh'},
                    { property: 'og:title', content: 'YTPlus - Kiểm tra network, Quản lý - Theo dõi đa kênh'},
                    { property: 'og:site_name', content: 'YTPlus'},
                    { property: 'og:description', content: 'Nền tảng tra cứu network và Quản lý - Theo dõi đa kênh'},
                    {property: 'og:type', content: 'WEBSITE'},
                ]
            }
        },
    }
</script>

<style lang="css" scoped>
</style>