import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store  from './stores';

import '../public/assets/plugins/@fortawesome/fontawesome-free/css/all.min.css';
import '../public/assets/plugins/bootstrap-icons/font/bootstrap-icons.css';
import '../public/assets/plugins/jquery-ui-dist/jquery-ui.min.css';
import '../public/assets/plugins/animate.css/animate.min.css';
import '../public/assets/plugins/pace-js/themes/black/pace-theme-flash.css';
import '../public/assets/plugins/perfect-scrollbar/css/perfect-scrollbar.css';
import '../public/assets/css/app.min.css';

const app = createApp(App)
app.use(router, { linkActiveClass: 'active' });
app.use(store);
app.mount('#app')