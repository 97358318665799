import UserVerifyModule from './verify';
import CryptoJS from 'crypto-js';
let account = {};
try {
    account = CryptoJS.AES.decrypt(localStorage.getItem('user'), process.env.VUE_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
} catch(err) {
    console.log(err?.message??null);
}

const state = account
    ? { status: { loggedIn: true }, account }
    : { status: {}, user: null };

const actions = {
    login({commit }, userData) {
        commit('login', userData);
    },
    setInfor({commit }, infor) {
        commit('setInfor', infor);
    },
    logout({ commit }) {
        commit('logout');
    }
};

const mutations = {
    login(state, user) {
        localStorage.setItem('user', CryptoJS.AES.encrypt(JSON.stringify(user), process.env.VUE_APP_SECRET_KEY));
        state.status = { loggedIn: true };
        state.account = user;
    },
    setInfor(state, infor) {
        localStorage.setItem('user', CryptoJS.AES.encrypt(JSON.stringify(infor), process.env.VUE_APP_SECRET_KEY));
        state.account = infor;
    },
    logout(state) {
        localStorage.removeItem('user');
        state.status = {};
        state.account = null;
    }
};

const getters = {
    getUser: (state) => {
        try {
            const user = state?.account?? {};
            return typeof user === 'string' ? JSON.parse(user) : user;
        } catch(err) {
            console.log(err);
            return {};
        }
    },
    getPermission: (state) => {
        return state?.account?.permission ?? [];
    },
    getAuth: (state) => {
        return state?.status?.loggedIn ?? false;
    },
    getEmail: (state) => {
        return state?.account?.email ?? 'Chưa xác định';
    }
};

export const user = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    modules: {
        verify: UserVerifyModule
    }
};

export default user