<template>
    <div class="alert-dismissible fade show" :class="typeClass" role="alert" v-if="textMessage.length > 0">
        {{ message }}
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"> </button>
    </div>
    <div class="alert-dismissible fade show" :class="typeClass" role="alert" v-if="arrayMessages.length > 0">
        <ul>
            <li v-for="item in arrayMessages" :key="item.strong">
                <strong>{{ item.strong }}</strong> {{ item.message }}
            </li>
        </ul>
        
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"> </button>
    </div>
    
</template> 

<script>
import utils from '../../helper/utils';

export default {
    props: {
        type: {
            type: String,
            default: "",
        },
        message: {
            type: String,
        }
    },
    setup(props) {
        let typeClass = "";
        let textMessage = "";
        let arrayMessages = [];
        switch(props.type) {
            case 'error': 
                typeClass = 'alert alert-danger';
                break;
            case 'success': 
                typeClass = 'alert alert-primary';
                break;
            default: 
                typeClass = 'alert alert-light';
                break;
        }

        if(utils.isJson(props.message)) arrayMessages = JSON.parse(props.message);
        else textMessage = props.message.toString();
        return { 
            typeClass,
            textMessage,
            arrayMessages
        };
    },
};
</script>

<style scoped>
    ul {
        margin-bottom: 0px;
    }
</style>