import Vuex from 'vuex';
import UserModule from './modules/user';

const store = new Vuex.Store({
    modules: {
        user: UserModule
    },
    computed: {
        ...Vuex.mapActions(['user']),
        ...Vuex.mapState(['user']),
        ...Vuex.mapGetters(['user'])
    }
});

export default store;