<template>
    <!-- BEGIN btn-scroll-top -->
    <a href="#" data-toggle="scroll-to-top" class="btn-scroll-top fade"><i class="fas fas-arrow-up"></i></a>
    <!-- END btn-scroll-top -->
</template>

<script>
    export default {
        name: 'ScrollToTop'
    }
</script>

<style lang="css" scoped>
</style>