import router from '../router';

const responseError = (resp, keys) => {
    let result = null;
    
    let redirectUrl = null;

    switch(resp.status_code) {
        case 401:
            redirectUrl = 'AuthLogin';
            break;
        case 404:
        case 500:
            redirectUrl = 'ErrorPageNotFound';
            break;
        case 406: 
            result = {
                type: 'error',
                message: resp.response.errors.msg
            };
            break;
        case 400:
            if(Array.isArray(resp.response.errors)) {
                let messages = [];
                
                resp.response.errors.map(x => {
                    messages.push({
                        key: x.key,
                        strong: keys[x.key],
                        message: x.msg
                    });
                });
                
                result = {
                    type: 'error',
                    reason: 'Validator',
                    message: JSON.stringify(messages)
                };
            } else {
                result = {
                    type: 'error',
                    reason: 'undefined',
                    message: resp.response.errors.msg
                };
            }
            break;
    }
    if(isEmpty(redirectUrl)) return result;
    else {
        router.push({ name: redirectUrl});
        return null;
    }
}

const isEmpty = (value) => {
    if(value === null 
        || typeof value === 'undefined') 
        return true;

    const typeOf = typeof value;
    let result = false;
    switch(typeOf) {
        case 'string': 
            if(value === '' || value === null || value === undefined) 
                result = true;
            break;
    }

    return result;
}

const limitString = (text, count) => {
    if(!text) return null;
    return text.slice(0, count) + (text.length > count ? "..." : "");
}

const isJson = (str) => {
    try {
        const a = JSON.parse(str);
        console.log(a);
    } catch (e) {
        return false;
    }
    return true;
}

const arraySlice = (data, size) => {
    const total = Math.ceil(data.length / size);
    const result = [];
    for (let i = 0; i <= total; i++) {
        const num = i * size;
        const end = num + size;
        result.push(data.slice(num, end));
    }

    return result;
};

function formatCurrency(n) {
    return this.formatThousand(n) + 'đ';
    // return n.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
}

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
            resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
            reject(error);
        };
    });
}

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

function formatThousand(value, separator) {
    separator = separator || ".";
    if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
    } else {
        return 0;
    }
}

function formatNumber(number) {
    if (!isNaN(number)) {
        if (number >= 1000000) return Math.round(number / 100000) / 10 + "M";

        if (number >= 1000) return Math.round(number / 100) / 10 + "K";

        return number;
    }

    return "-";
}

export default {
    responseError: responseError,
    isEmpty: isEmpty,
    limitString: limitString,
    isJson: isJson,
    arraySlice: arraySlice,
    formatCurrency: formatCurrency,
    getBase64: getBase64,
    formatDate,
    formatThousand,
    formatNumber,
};