<template>
    <div class="modal modal-cover fade show" style="display: block;" aria-modal="true" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header p-0 py-3">
                    <h3 class="modal-title">Nhập file</h3>
                    <button type="button" class="btn-close" @click="turnOnOffImport"></button>
                </div>
                <Form @submit="onSubmit" :validation-schema="schema" class="modal-body card" v-if="showInput">
                    <div class="alert alert-success" v-if="messageSuccess!==null">
                        {{ messageSuccess }}
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Công ty chứng khoán</label>
                        <SelectInput 
                            name="itemStockCompany" 
                            placeholder="Chọn 1 công ty"
                            v-bind:items="itemsStockCompany"/>
                    </div>
                    <div class="form-group mb-3">
                        <label class="form-label">File excel</label>
                        <FileUpload name="file"/>
                    </div>
                    <div class="form-group">
                        <button type="submit" class="btn btn-outline-theme">Lưu file</button>
                    </div>
                    <div class="card-arrow">
                        <div class="card-arrow-top-left"></div>
                        <div class="card-arrow-top-right"></div>
                        <div class="card-arrow-bottom-left"></div>
                        <div class="card-arrow-bottom-right"></div>
                    </div>
                </Form>
                <div class="alert alert-light" v-else>
                    <div class="spinner-border spinner-border-sm text-primary"></div>
                    Dữ liệu đang được thêm vào vui lòng chờ ...
                </div>
            </div>
        </div>
    </div>
    <div class="modal-backdrop fade show"></div>
</template>

<script>
    import * as yup from 'yup';
    import { Form } from 'vee-validate';

    import Helper from '../../../helper/utils';
    import request from '../../../helper/request';

    import SelectInput from '../../components/Form/Select.vue';
    import FileUpload from '../../components/Form/FileUploadBasic.vue';
    
    const FILE_SIZE = 160 * 1024;
    const SUPPORTED_FORMATS = [
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "text/csv"
    ];

    export default {
        name: 'Stock',
        emits: ["turnOnOffImport"],
        data() {
            const schema = yup.object().shape({
                itemStockCompany: yup.string().required('Trường bắt buộc'),
                file: yup.mixed()
                    .required("A file is required")
                    .test(
                        "fileSize",
                        "File too large",
                        value => value && value.size <= FILE_SIZE
                    ).test(
                        "fileFormat",
                        "Chỉ nhận file *.csv | *.xls | *.xlsx",
                        value => value && SUPPORTED_FORMATS.includes(value.type)
                    )
            });
            return {
                schema,
                breadcrumbs: [{'link': 'FinanceStock', 'text': 'Chứng Khoán'}],
                itemsStockCompany: [{key: 'VNDIRECT', value: 'VNDIRECT'},{key: 'YSWINNER', value: 'YSWINNER'}],
                error: null,
                showInput: true,
                messageSuccess: null,
                countDown: 0,
            };
        }, 
        components: {
            Form,
            SelectInput,
            FileUpload
        },
        methods: {
            async onSubmit(values) {
                this.showInput = false;
                const params = {};
                params['stock_company'] = values.itemStockCompany;
                params['file'] = await Helper.getBase64(values.file);

                request.post(`${process.env.VUE_APP_BASE_API}/v1/api/administrator/finance/stock/create`, params)
                    .then((data) => {
                        this.showInput = true;
                        if(data.status === 'success') {
                            this.countDown = 5;
                            this.messageSuccess = `Thêm dữ liệu thành công. màn hình sẽ tự tắt sau ${this.countDown}s`;
                            this.countDownTimer();
                        } else {
                            this.error = Helper.responseError(data, []);
                        }
                    });
            },
            countDownTimer: function() {
                if (this.countDown > 0) {
                    setTimeout(() => {
                        this.countDown -= 1
                        this.messageSuccess = `Thêm dữ liệu thành công. màn hình sẽ tự tắt sau ${this.countDown}s`;
                        this.countDownTimer()
                    }, 1000)
                } else this.turnOnOffImport();
            },
            turnOnOffImport: function() {
                this.$emit('turnOnOffImport');
            }
        }
    }
</script>

<style scoped>
    .life_cycle {
        color: rgba(255,255,255,.5);
        font-weight: 600;
    }
</style>