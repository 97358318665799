<template>
    <div class="modal modal-cover fade show" style="display: block;" aria-modal="true" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header p-0 py-3">
                    <h3 class="modal-title">Thêm phân quyền</h3>
                    <button type="button" class="btn-close" @click="turnOffAdd"></button>
                </div>
                <div class="modal-body p-0">
                    <div class="card">
                        <div class="card-body">
                            <template v-if="error !== null">
                                <Alert 
                                    :type="error.type" 
                                    :message="error.message"/>
                            </template>
                            <template v-else>
                                <div class="alert alert-success" v-if="messageSuccess!==null">
                                    {{ messageSuccess }}
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">Tên phân quyền</label>
                                    <input 
                                        id="name" 
                                        name="name" 
                                        type="text" 
                                        v-model="name" 
                                        class="form-control"
                                        :class="validator.name !== null && validator.name.length > 0 ? 'is-invalid': ''"/>
                                    <div class="invalid-feedback" v-if="validator.name !== null && validator.name.length > 0">{{ validator.name }}</div>
                                </div>
                                <div class="mb-0">
                                    <label class="form-label">Mô tả</label>
                                    <textarea 
                                        class="form-control" 
                                        id="describe" 
                                        name="describe" 
                                        v-model="describe" 
                                        rows="3"
                                        :class="validator.describe !== null && validator.describe.length > 0 ? 'is-invalid': ''"
                                    ></textarea>
                                    <div class="invalid-feedback" v-if="validator.describe !== null && validator.describe.length > 0">{{ validator.describe }}</div>
                                </div>
                            </template>
                        </div>
                        <div class="card-footer bg-none" style="z-index: 9;">
                            <button type="submit" class="btn btn-outline-theme" @click="onSubmit">Lưu dữ liệu</button>
                            <button type="button" class="btn btn-outline-danger ms-1" @click="turnOffAdd">Huỷ</button>
                        </div>
                        <div class="card-arrow">
                            <div class="card-arrow-top-left"></div>
                            <div class="card-arrow-top-right"></div>
                            <div class="card-arrow-bottom-left"></div>
                            <div class="card-arrow-bottom-right"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-backdrop fade show"></div>
</template>

<script>
    import Helper from '../../../helper/utils';
    import request from '../../../helper/request';
    import Alert from '../../components/Alert.vue';

    export default {
        name: 'AddRole',
        emits: ["turnOffAdd"],
        data() {
            return {
                error: null,
                messageSuccess: null,
                name: null,
                describe: null,
                key: null,
                resultId: null,
                validator: {
                    name: null,
                    describe: null
                }
            };
        },
        components: {
            Alert
        },
        methods: {
            onSubmit: function () {
                request.post(`${process.env.VUE_APP_BASE_API}/v1/api/administrator/roles`, {
                    name: this.name,
                    describe: this.describe
                })
                    .then((data) => {
                        const keys = {
                            name: 'Tên nhóm',
                            describe: 'Mô tả'
                        };

                        Object.keys(this.validator).forEach((v) => {
                            this.validator[v] = null;
                        })
                        
                        if(data.status === 'success') {
                            this.countDown = 5;
                            this.resultId = data?.response?.detail?.id ?? null;
                            this.messageSuccess = `Thêm dữ liệu thành công. màn hình sẽ tự tắt sau ${this.countDown}s`;
                            this.countDownTimer();
                        } else {
                            const err = Helper.responseError(data, keys);
                            if(err.reason === 'Validator') {
                                const messages = JSON.parse(err.message);
                                messages.forEach(element => {
                                    this.validator[element.key] = element.message;
                                });
                            } else {
                                this.error = err;
                            }
                        }
                    });
            },
            countDownTimer: function() {
                if (this.countDown > 0) {
                    setTimeout(() => {
                        this.countDown -= 1
                        this.messageSuccess = `Thêm dữ liệu thành công. màn hình sẽ tự tắt sau ${this.countDown}s`;
                        this.countDownTimer()
                    }, 1000)
                } else this.turnOffAdd(true);
            },
            turnOffAdd: function(loaddata = null) {
                this.$emit('turnOffAdd', false, loaddata, this.resultId);
            }
        }
    }
</script>