<template>
    <div class="modal modal-cover fade show" style="display: block;" aria-modal="true" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header p-0 py-3">
                    <h3 class="modal-title">Thêm Giao Dịch</h3>
                    <button type="button" class="btn-close" @click="turnOffInsert"></button>
                </div>
                <div class="modal-body p-0">
                    <div class="card">
                        <div class="card-body">
                            <template v-if="error !== null">
                                <Alert 
                                    :type="error.type" 
                                    :message="error.message"/>
                            </template>
                            <div class="alert alert-success" v-if="messageSuccess!==null">
                                {{ messageSuccess }}
                            </div>
                            <div class="mb-3">
                                <label class="form-label">Thời gian</label>
                                <Datepicker
                                    id="day"
                                    name="day"
                                    class="form-control"
                                    v-model="day"
                                    :class="validator.day !== null && validator.day.length > 0 ? 'is-invalid': ''"/>
                                <div class="invalid-feedback" style="display: block;" v-if="validator.day !== null && validator.day.length > 0">{{ validator.day }}</div>
                            </div>
                            <div class="mb-3">
                                <label class="form-label">Công ty chứng khoán</label>
                                <select 
                                    id="company"
                                    name="company"
                                    class="form-select" 
                                    v-model="company"
                                    :class="validator.company !== null && validator.company.length > 0 ? 'is-invalid': ''">
                                    <option>Chọn một loại</option>
                                    <option value="VNDIRECT">VNDIRECT</option>
                                    <option value="YSWINNER">YSWINNER</option>
                                </select>
                            </div>
                            <div class="mb-3 row" style="z-index: 9;">
                                <div class="col-6">
                                    <label class="form-label">Phân Loại</label>
                                    <select 
                                        id="type"
                                        name="type"
                                        class="form-select" 
                                        v-model="type"
                                        :class="validator.type !== null && validator.type.length > 0 ? 'is-invalid': ''">
                                        <option>Chọn một loại</option>
                                        <option value="RECHARGE">Nạp tiền vào tài khoản</option>
                                        <option value="BUY">Mua cổ phiếu</option>
                                        <option value="SELL">Bán cổ phiếu</option>
                                        <option value="WITHDRAWALS">Rút tiền</option>
                                        <option value="PAY_THE_DEPOSIT_FEE">Phí lưu ký</option>
                                        <option value="INTEREST_RATE">Lãi suất</option>
                                        <option value="DIVIDEND">Cổ tức</option>
                                    </select>
                                    <div class="invalid-feedback" v-if="validator.type !== null && validator.type.length > 0">{{ validator.type }}</div>
                                </div>
                                <div class="col-6">
                                    <label class="form-label">Tiền</label>
                                    <input 
                                        id="price" 
                                        name="price" 
                                        type="number" 
                                        v-model="price" 
                                        class="form-control"
                                        :class="validator.price !== null && validator.price.length > 0 ? 'is-invalid': ''"/>
                                    <div class="invalid-feedback" v-if="validator.price !== null && validator.price.length > 0">{{ validator.price }}</div>
                                </div>
                            </div>
        
                            <div class="mb-0" style="z-index: 9;">
                                <label class="form-label">Nội dung</label>
                                <textarea class="form-control" id="content" name="content" v-model="content" rows="3"></textarea>
                            </div>
                        </div>
                        <div class="hljs-container" v-if="type === 'BUY' || type === 'SELL'">
                            <div class="mb-3 row">
                                <div class="col-6">
                                    <label class="form-label">Mã Chứng Khoán</label>
                                    <input 
                                        id="symbol" 
                                        name="symbol" 
                                        type="text" 
                                        v-model="symbol_stock" 
                                        class="form-control"
                                        :class="validator.symbol_stock !== null && validator.symbol_stock.length > 0 ? 'is-invalid': ''"/>
                                    <div class="invalid-feedback" v-if="validator.symbol_stock !== null && validator.symbol_stock.length > 0">{{ validator.symbol_stock }}</div>
                                </div>
                                <div class="col-6">
                                    <label class="form-label">Khối lượng</label>
                                    <input 
                                        id="mass" 
                                        name="mass" 
                                        type="number" 
                                        v-model="mass" 
                                        class="form-control"
                                        :class="validator.mass !== null && validator.mass.length > 0 ? 'is-invalid': ''"/>
                                    <div class="invalid-feedback" v-if="validator.mass !== null && validator.mass.length > 0">{{ validator.mass }}</div>
                                </div>
                            </div>
                            <div class="mb-3 row">
                                <div class="col-6">
                                    <label class="form-label">Phí giao dịch</label>
                                    <input 
                                        id="fee" 
                                        name="fee" 
                                        type="number" 
                                        v-model="fee" 
                                        class="form-control"
                                        :class="validator.fee !== null && validator.fee.length > 0 ? 'is-invalid': ''"/>
                                    <div class="invalid-feedback" v-if="validator.fee !== null && validator.fee.length > 0">{{ validator.fee }}</div>
                                </div>
                                <div class="col-6">
                                    <label class="form-label">Thuế</label>
                                    <input 
                                        id="tax" 
                                        name="tax" 
                                        type="number" 
                                        v-model="tax" 
                                        class="form-control"
                                        :class="validator.tax !== null && validator.tax.length > 0 ? 'is-invalid': ''"/>
                                    <div class="invalid-feedback" v-if="validator.tax !== null && validator.tax.length > 0">{{ validator.tax }}</div>
                                </div>
                            </div>
                            <div class="mb-3 row" v-if="type === 'BUY'">
                                <div class="col-6">
                                    <label class="form-label">Cắt loss</label>
                                    <input 
                                        id="cut_loss" 
                                        name="cut_loss" 
                                        type="number" 
                                        v-model="cut_loss" 
                                        class="form-control"
                                        :class="validator.cut_loss !== null && validator.cut_loss.length > 0 ? 'is-invalid': ''"/>
                                    <div class="invalid-feedback" v-if="validator.cut_loss !== null && validator.cut_loss.length > 0">{{ validator.cut_loss }}</div>
                                </div>
                                <div class="col-6">
                                    <label class="form-label">Target</label>
                                    <input 
                                        id="target" 
                                        name="target" 
                                        type="text" 
                                        v-model="target" 
                                        class="form-control"
                                        :class="validator.target !== null && validator.target.length > 0 ? 'is-invalid': ''"/>
                                    <div class="invalid-feedback" v-if="validator.target !== null && validator.target.length > 0">{{ validator.target }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer bg-none" style="z-index: 9;">
                            <button type="submit" class="btn btn-outline-theme" @click="onSubmit">Lưu dữ liệu</button>
                            <button type="button" class="btn btn-outline-danger ms-1" @click="turnOffInsert">Huỷ</button>
                        </div>
                        <div class="card-arrow">
                            <div class="card-arrow-top-left"></div>
                            <div class="card-arrow-top-right"></div>
                            <div class="card-arrow-bottom-left"></div>
                            <div class="card-arrow-bottom-right"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-backdrop fade show"></div>
</template>

<script>
    import { ref } from 'vue';
    import Datepicker from 'vue3-datepicker';
    import Helper from '../../../helper/utils';
    import request from '../../../helper/request';
    import Alert from '../../components/Alert.vue';

    export default {
        name: 'CreateStock',
        emits: ["turnOffInsert"],
        data() {
            return {
                day: ref(new Date()),
                type: null,
                price: null,
                company: null,
                messageSuccess: null,
                countDown: 0,
                error: null,
                content: null,
                symbol_stock: null,
                mass: 0,
                tax: 0,
                cut_loss: 0,
                target: 0,
                fee: 0,
                validator: {
                    day: '',
                    price: '',
                    type: '',
                    symbol_stock: '',
                    mass: '',
                    fee: '',
                    tax: '',
                    cut_loss: '',
                    target: '',
                    company: ''
                }
            };
        }, 
        components: {
            Datepicker,
            Alert
        },
        methods: {
            onSubmit: function () {
                const params = {};
                params['type'] = this.type;
                params['day'] = Helper.formatDate(this.day);
                params['price'] = this.price;
                params['content'] = this.content;
                params['company'] = this.company;
                if(this.type === 'BUY' || this.type === 'SELL') {
                    params['detail'] = {
                        symbol: this.symbol_stock,
                        mass: this.mass,
                        sign: null,
                        fee: this.fee,
                        tax: this.tax
                    };
                    if(this.type === 'BUY') {
                        params['detail']['cut_loss'] = this.cut_loss;
                        params['detail']['target'] = this.target;
                    }
                }
            
                this.validator.day = null;

                request.post(`${process.env.VUE_APP_BASE_API}/v1/api/administrator/finance/stock`, params)
                    .then((data) => {
                        const keys = {
                            day: 'Thời gian',
                            type: 'Phân loại',
                            price: 'Giá',
                            company: 'Công ty'
                        }
                        
                        if(data.status === 'success') {
                            this.countDown = 5;
                            this.messageSuccess = `Thêm dữ liệu thành công. màn hình sẽ tự tắt sau ${this.countDown}s`;
                            this.countDownTimer();
                        } else {
                            const err = JSON.parse(Helper.responseError(data, keys).message);
                            if(err.reason === 'Validator') {
                                err.forEach(element => {
                                    switch(element.key) {
                                        case 'day': 
                                            this.validator.day = element.message;
                                            break;
                                        case 'price':
                                            this.validator.price = element.message;
                                            break;
                                        case 'type':
                                            this.validator.type = element.message;
                                            break;
                                        case 'company':
                                            this.validator.company = element.message;
                                            break;
                                    }
                                });
                            } else {
                                this.error = err;
                            }
                        }
                    });
            },
            countDownTimer: function() {
                if (this.countDown > 0) {
                    setTimeout(() => {
                        this.countDown -= 1
                        this.messageSuccess = `Thêm dữ liệu thành công. màn hình sẽ tự tắt sau ${this.countDown}s`;
                        this.countDownTimer()
                    }, 1000)
                } else this.turnOffInsert();
            },
            turnOffInsert: function() {
                this.$emit('turnOffInsert');
            }
        }
    }
</script>