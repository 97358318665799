<template>
    <div class="modal modal-cover fade show" style="display: block;" aria-modal="true" role="dialog">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-body">
					<div class="card bg-danger border-danger bg-opacity-25 mb-3">
						<div class="card-header border-danger fw-bold text-white">
							<div class="d-flex fw-bold">
								<span class="flex-grow-1">Xác nhận xoá dữ liệu!</span>
								<a href="javascript:void(0)" data-toggle="card-expand" class="text-white text-opacity-50 text-decoration-none" data-bs-original-title="" title="" @click="turnOff(false)">
									<i class="bi bi-x-lg"></i>
								</a>
							</div>
						</div>
						<div class="card-body">
							<div class="row" v-if="this.error">
								<div class="col-lg-12">
									<alert :type="this.error.type" :message="this.error.message"/>
								</div>
							</div>
							<div class="alert alert-success" v-if="messageSuccess!==null">
								{{ messageSuccess }}
							</div>
							<p class="card-text">
								Dữ liệu đã xoá sẽ không thể phục hồi<br>
								Bạn có muốn tiếp tục không ?
							</p>
						</div>
						<div class="card-footer">
							<button type="button" class="btn btn-outline-danger me-1" @click="deleteData()">
								<div class="spinner-border spinner-border-sm" v-if="loading === true"></div>
								Xác nhận xoá
							</button>
							<button type="button" class="btn btn-outline-default" @click="turnOff(false)">Huỷ bỏ</button>
						</div>
						<div class="card-arrow">
							<div class="card-arrow-top-left"></div>
							<div class="card-arrow-top-right"></div>
							<div class="card-arrow-bottom-left"></div>
							<div class="card-arrow-bottom-right"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-backdrop fade show"></div>
</template>

<script>
import alert from '../../components/Alert.vue';

import utils from '../../../helper/utils';
import request from '../../../helper/request';

export default {
	name:"PopupDelete",
	emits: ["callback"],
	components: {
		alert
	},
	data() {
		return {
			loading: false,
			error: null,
			countDown: 0,
			messageSuccess: null
		};
	},
    props: {
        id: {
            type: String
        },
		url: {
			type: String
		}
    },
    methods: {
        turnOff: function(result) {
			this.$emit('callback', null, result);
		},
		deleteData: function() {
			this.loading = true;
			request.delete(this.url)
				.then((resp) => {
					this.loading = false;
					if(resp.status_code === 200
						|| resp.status_code === 204) {
						this.countDown = 3;
						this.messageSuccess = `Xoá dữ liệu thành công. màn hình sẽ tự tắt sau ${this.countDown}s`;
						this.countDownTimer();
					} else this.error = utils.responseError(resp, {});
				}).catch((err) => {
					this.loading = false;
					console.log(err);
					this.$router.push({ name: 'ErrorServerError'});
				});
		},
		countDownTimer: function() {
			if (this.countDown > 0) {
				setTimeout(() => {
					this.countDown -= 1
					this.messageSuccess = `Xoá dữ liệu thành công. màn hình sẽ tự tắt sau ${this.countDown}s`;
					this.countDownTimer()
				}, 1000)
			} else this.turnOff(true);
		},
    }
}
</script>