<template>
    <Header/>
    <Menu />
    <div id="content" class="app-content">
        <div class="d-flex align-items-center mb-md-3 mb-2">
            <div class="flex-fill">
                <Breadcrumb :items="breadcrumbs" />
                <h1 class="page-header mb-0">
                    Chứng khoán
                </h1>
            </div>
            <div class="ms-auto">
                <button @click="modalShow" class="btn btn-outline-theme">
                    <i class="fa fa-plus-circle me-1"></i> 
                    Add file
                </button>
            </div>
        </div>
        <div class="mb-sm-4 mb-3 d-sm-flex">
            <div class="mt-sm-0 mt-2">
                <a href="javascript:void(0)" @click="turnOnOffImport" class="text-white text-opacity-75 text-decoration-none">
                    <i class="fa fa-upload fa-fw me-1 text-theme"></i> Import
                </a>
            </div>
            <div class="ms-sm-4 mt-sm-0 mt-2">
                <a href="javascript:void(0)" @click="turnOnOffInsert" class="text-white text-opacity-75 text-decoration-none">
                    <i class="fa fa-plus fa-fw me-1 text-theme"></i> Thêm Mới
                </a>
            </div>
        </div>
        <Create 
            @turnOffInsert="turnOnOffInsert"
            v-if="openInsert"/>
        <Import @turnOnOffImport="turnOnOffImport" 
            v-if="openImport"/>
        <div class="row">
            <div class="col-xl-6">
                <WarehouseList />
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <DataTableTransaction />
            </div>
        </div>
    </div>
</template>

<script>
    import Menu from '../components/Menu.vue';
    import Header from '../components/Header.vue';
    import Breadcrumb from '../components/Breadcrumb.vue';

    import Create from './components/create.vue';
    import Import from './components/Import.vue';
    import DataTableTransaction from './components/DataTableTransaction.vue';
    import WarehouseList from './components/Warehouse.vue';
    export default {
        name: 'Stock',
        data() {
            return {
                breadcrumbs: [{'link': 'FinanceStock', 'text': 'Chứng Khoán'}],
                error: null,
                openInsert: false,
                openImport: false
            };
        }, 
        components: {
            Header,
            Menu,
            Breadcrumb,
            Create,
            Import,
            DataTableTransaction,
            WarehouseList
        },
        methods: {
            turnOnOffInsert: function() {
                this.openInsert = !this.openInsert;
            },
            turnOnOffImport: function() {
                this.openImport = !this.openImport;
            }
        },
        created() {
            document.body.classList.remove('pace-top');
            document.getElementById('app').classList.remove('app-full-height','app-without-header');
        },
    }
</script>