<template>
    <div class="error-page">
        <div class="error-page-content">
            <div class="card mb-5 mx-auto" style="max-width: 320px;">
                <div class="card-body">
                    <div class="card">
                        <div class="error-code">{{ status_code }}</div>
                        <div class="card-arrow">
                            <div class="card-arrow-top-left"></div>
                            <div class="card-arrow-top-right"></div>
                            <div class="card-arrow-bottom-left"></div>
                            <div class="card-arrow-bottom-right"></div>
                        </div>
                    </div>
                </div>
                <div class="card-arrow">
                    <div class="card-arrow-top-left"></div>
                    <div class="card-arrow-top-right"></div>
                    <div class="card-arrow-bottom-left"></div>
                    <div class="card-arrow-bottom-right"></div>
                </div>
            </div>
            <h1>Oops!</h1> 
            <h3>{{ message }}</h3>
            <hr>
            <a href="javascript:void(0);" @click="$router.go(-2)" class="btn btn-outline-theme px-3 rounded-pill"><i class="fa fa-arrow-left me-1 ms-n1"></i> Go Back</a>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Display Error',
        props: {
            status_code: {
                type: String,
                default: "",
            },
            message: {
                type: String,
                default: "",
            }
        }
    }
</script>
